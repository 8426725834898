.role-edit-btn-cities{
    width: 43%;
    height: 12%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;  
  }    
