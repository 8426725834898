@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Merriweather:ital,wght@1,700&display=swap');

.home-label-form {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    letter-spacing: 0.02em;
    color: #000000;
}

.date-home-input {
    background: white;
    width: 90%;
    height: 2.5vw;
    font-weight: 800;
    font-size: 0.8vw;
    font-weight: 400;
    border-radius: 50px;
    border-color: #000000;
}

.home-filter-date-btn {
    width: 33%;
    height: 2.5vw;
    font-weight: 800;
    font-size: 0.8vw;
    font-weight: 400;
    color: white;
    font-family: 'Almarai', sans-serif;
    background: #000000;
    border-radius: 50px;
    font-style: normal;
    border-style: none;
    outline-style: none;
}

.homeCard-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    /* font-weight: 800; */
    font-size: 18px;
    letter-spacing: 0.02em;
    padding: 7px;
    /* text-decoration: underline; */
    color: #000000;
}

.home-card-number {
    font-family: 'Lobster', cursive;
    font-family: 'Merriweather', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #00BD85;
}

.charts-container-layout {
    position: absolute;
    left: 13%;
    width: 80%;
    top: 51rem;
}

.charts-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.home-pie-chart {
    width: 27vw;
    height: 27vh;
    margin-top: 8%;
    margin-left: -4%;
    font-family: 'Lobster', cursive;
    font-family: 'Merriweather', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.5vw;
}

.chart-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1.1vw;
    width: 87%;
    text-align: center;
}

.charts-info-container {
    display: flex;
    justify-content: center;
    padding-top: 2em;
}

.charts-info {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.1vw;
    width: 87%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.home-select-city-filter {
    width: 90%;
    height: 2.5vw;
    font-weight: 800;
    font-size: 0.8vw;
    font-weight: 400;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
}

.error-chart-response-icon {
    font-size: 1.5vw;
    color: red;
}

.error-chart-response-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    letter-spacing: 0.02em;
    margin-left: 0.5vw;
    color: red;
}

/* 120% l fo2  */
@media only screen and (max-width: 1597px) {
    .homeCard-title {
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        /* font-weight: bold; */
        font-size: 15px;
        letter-spacing: 0.02em;
        padding: 6px;
        /* text-decoration: underline; */
        color: #000000;
    }

    .home-card-number {
        font-family: 'Lobster', cursive;
        font-family: 'Merriweather', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        color: #00BD85;
    }

}

/* 150 l fo2 */
@media only screen and (max-width: 1300px) {
    .homeCard-title {
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        /* font-weight: bold; */
        font-size: 13px;
        letter-spacing: 0.02em;
        padding: 5px;
        /* text-decoration: underline; */
        color: #000000;
    }

    .home-card-number {
        font-family: 'Lobster', cursive;
        font-family: 'Merriweather', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 23px;
        color: #00BD85;
    }

}