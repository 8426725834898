.img-error-screen{
    width: 18vw;
    margin-top: 12vh;
}
.error-screen-text-msg{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    letter-spacing: 0.02em;
    color: #F4B844;  
    margin-top: 6vh;
}
.back-home-btn{
    margin-top: 6vh;
    width: 80%;
    padding: 4.5%;
    font-family: 'Almarai', sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: #FFFFFF;
    background: #00BD85;
    border-radius: 50px;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
}
  /* 120% l fo2  */
  @media only screen and (max-width: 1597px) {
    .error-screen-text-msg{
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        letter-spacing: 0.02em;
        color: #F4B844;  
        margin-top: 7vh;
    }
    .back-home-btn{
        margin-top: 6vh;
        width: 80%;
        padding: 4%;
        font-family: 'Almarai', sans-serif;
        font-weight: bold;
        font-size: 17px;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 50px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
    }
    
    
  }
/* 150 l fo2 */
@media only screen and (max-width: 1300px) {
    .error-screen-text-msg{
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        letter-spacing: 0.02em;
        color: #F4B844;  
        margin-top: 7vh;
    }
    .back-home-btn{
        margin-top: 6vh;
        width: 80%;
        padding: 4%;
        font-family: 'Almarai', sans-serif;
        font-weight: bold;
        font-size: 17px;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 50px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
    }
    
}   

/* mobile */
@media only screen and (max-width: 1100px) {
    .img-error-screen{
        width: 18vw;
        margin-top: 40vh;
    }
    .error-screen-text-msg{
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        letter-spacing: 0.02em;
        color: #F4B844;  
        margin-top: 4vh;
    }
    .back-home-btn{
        margin-top: 4vh;
        width: 100%;
        padding: 4%;
        font-family: 'Almarai', sans-serif;
        font-size: 11px;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 50px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
    }

}    