html {
  background-color: white;
}

.agelFontFamily {
  font-family: 'Almarai', sans-serif;
}

.table>:not(:first-child) {
  border-top: 2px solid;
}

.table>tbody>tr>td {
  vertical-align: middle !important;
}

.table {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2) !important;
}

/* de 34an yseb el tr ttlwan w2t may3ml hover 3l row f el accounts table wba2e el 7agat el mo4bha */
/* .table {
  --bs-table-bg: none!important;
} */

.expand-screen-layout {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1em;
  text-transform: capitalize;
  text-align: center;
  margin-top: 45vh;
  color: #00BD85;
  overflow-y: hidden;
  overflow-x: hidden;
}

.dashboard-font {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}

.dashboard-error-msg {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  display: flex;
  justify-content: start;
  align-items: center;
  letter-spacing: 0.02em;
  color: #FF0000;
}

.expand-screen-layout span {
  margin-left: 0.5em;
  margin-top: -2.9vh;
}

.rubberBand {
  animation: rubberBand;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 3s;
  animation-iteration-count: infinite;
  /* animate-repeat: 2 */
}

.layout-top {
  position: absolute;
  width: 80%;
  margin-left: 13%;
  margin-top: 6%;
}

.layout-row-2 {
  position: absolute;
  width: 80%;
  margin-left: 13%;
  margin-top: 9.8%;
}

.layout-row-3 {
  position: absolute;
  width: 80%;
  margin-left: 13%;
  margin-top: 14.2%;
}

.tables-layout {
  position: absolute;
  left: 13%;
  width: 80%;
  top: 37%;
}

.profile-page-layout {
  position: absolute;
  left: 13%;
  width: 80%;
  top: 8%;
}

/* .input-wrapper {
    position: absolute;
    margin-left:15%;
    margin-top: 10%;
  } */
.frame {
  position: relative;

  width: 193px;
  height: 185px;

  /* background-image:url('public/images/Ellipse-388.png'); */
  /* background-image:linear-gradient(red, yellow, green); */
  background-position: center;
  background-size: cover;
}

.centered-image {
  position: absolute;
  /* left: 50%;
    top: 50%;
    right: 50%;
    bottom: 50%; */

  display: block;
  margin: auto;
}

.span-profile {
  display: block;
  font-size: 1vw;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
}

.input-wrapper:before {
  content: "";
  position: absolute;
  left: 2%;
  top: 1%;
  text-align: center;
  bottom: 0;
  width: 22px;
  background: url("./icons/searchRole.svg") center / contain no-repeat;
}

.input-wrapper input {
  width: 100%;
  height: 3em;
  padding-left: 3em;
  padding-right: 3em;
  font-family: 'Almarai', sans-serif;
  outline: none;
  border: 1px solid #5E5E5E;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 15px;

}

.role-search-btn {
  /* position: absolute; */
  width: 7em;
  height: 3em;
  /* margin-left:33vw;
  margin-top: 10%; */
  color: white;
  font-family: 'Almarai', sans-serif;
  background: #000000;
  border-radius: 50px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  border-style: none;
  outline-style: none;
}

.add-role-btn {
  width: 15em;
  height: 3em;
  /* margin-top: 10%;
    margin-left: 80%; */
  background: #00BD85;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  text-transform: capitalize;
  text-align: center;
  color: #FFFFFF;
  border-style: none;
  outline-style: none;
}

.city-select-btn {
  /* position: absolute; */
  width: 7em;
  height: 3em;
  /* margin-left:33vw;
  margin-top: 10%; */
  color: #00BD85;
  font-family: 'Almarai', sans-serif;
  background: #ffffff;
  border-radius: 50px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  text-align: center;
  border: 1px solid #00BD85;
  outline-style: 1px solid #00BD85;
  /* border-style: none; */
}

.add-role-btn p {
  margin-top: 0.7em;
  margin-left: 0.5em;
  font-size: 1.1em;
}

.add-role-btn-icon {
  margin-top: -2px;
}

.role-edit-btn {
  width: 50%;
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #000000;
  border-radius: 25px;
  border-color: #000000;
  border-style: none;
  outline-style: none;
}

.staff-edit-btn {
  width: 50%;
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #000000;
  border-radius: 25px;
  border-color: #000000;
  border-style: none;
  outline-style: none;
}

.reset-password-btn-staff {
  width: 50%;
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #4eddf0;
  border-radius: 25px;
  border-color: #4eddf0;
  border-style: none;
  outline-style: none;
  margin-left: 4px;
}

.agent-reset-btn {
  width: 50%;
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #4eddf0;
  border-radius: 25px;
  border-color: #4eddf0;
  border-style: none;
  outline-style: none;
  margin-left: 4px;
}

.agent-edit-btn {
  width: 50%;
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #000000;
  border-radius: 25px;
  border-color: #000000;
  border-style: none;
  outline-style: none;
  margin-left: 4px;
}


.role-table-header th {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

/* .modal-header .btn-close {
  border: 2px solid black !important;
  border-radius: 50% !important;
  padding: 0.5vw;
  font-size: 0.6vw !important;
  opacity: 0.8 !important;
  border-style: none;
  outline-style: none;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
} */

.modal-content {
  width: 50em !important;
  border-radius: 39px;
  padding: 1em;
  margin-bottom: 6em;
}

.role-table-text td {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 2em; */
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #5E5E5E;
  vertical-align: middle;
}

.form-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 22px; */
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.form-select.AF-select {
  display: block;
  width: 100%;
  padding: .355rem 2.25rem .355rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  /* line-height: 1.5; */
  background: #E5E5E5;
  /* color: #5E5E5E; */
  /* background-color: #fff; */
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 12px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
}

.form-select.select-trade {
  display: block;
  width: 100%;
  padding: .355rem 2.25rem .355rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  /* line-height: 1.5; */
  background: #E5E5E5;
  /* color: #5E5E5E; */
  /* background-color: #fff; */
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left .75rem center;
  background-size: 12px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
}

.form-select.customize-select {
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  /* line-height: 1.5; */
  background: #E5E5E5;
  /* color: #5E5E5E; */
  /* background-color: #fff; */
  background: url("./icons/arrow-down-green.svg");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 19px 19px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
}

.agent-select {
  display: block;
  width: 100%;
  padding: .355rem 2.25rem .355rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  /* line-height: 1.5; */
  background: #E5E5E5;
  /* color: #5E5E5E; */
  /* background-color: #fff; */
  background: url("./icons/downArrowBlack.svg");
  background-repeat: no-repeat;
  background-position: left .75rem center;
  background-size: 12px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
}


.password-confirm-msg {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 0.02em;
  color: #ff0000;
}

.role-input {
  background: #ffffff;
  height: 3em;
  font-size: 0.9em;
  border-radius: 50px;
}

/* check box shape */
.status input {
  background: #E5E5E5;
  border-radius: 50px;
  width: 1.1em;
  height: 1.1em;
  outline: none;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E");
}

.status input:checked {
  border-color: #00BD85;
  background-color: #00BD85;
}

.side-bar-profile {
  border-right: 1px solid gray;
}

.side-bar-note-profile {
  background-color: #E5E5E5;
  border-radius: 50px;
  height: auto;
  width: auto;

}

.side-bar-note-profile-span {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 25px;
  height: auto;
  width: auto;
  padding: 0.6em;

  font-family: 'Almarai';
  font-style: normal;
  font-weight: 510;
  font-size: 12px;
  line-height: 25px;
  text-transform: capitalize;

}

.side-bar-note-add-note-span {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 18px;
  text-transform: capitalize;
  color: #00BD85;
  margin: 4px 4px;
}

.side-note-word-span {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height */

  letter-spacing: 0.02em;
  text-transform: capitalize;
}

.side-note-plus-span {
  width: 1.4em;
  height: 1.4em;
}

.add-role-table-header {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 22px; */
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #5E5E5E;
}

.add-role-table-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 22px; */
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.check-functionality .form-check-input {
  background: #E5E5E5;
  border-radius: 20px;
  border-color: #E5E5E5;
  width: 1.2em !important;
  height: 1.2em !important;
}

.check-functionality .form-check-input:checked {
  border-color: #00BD85;
  background-color: #00BD85;
}

.role-done-btn {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  width: 6em;
  height: 2.3em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.role-close-btn {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  width: 6em;
  height: 2.3em;
  background: #FF0000;
  border-color: #FF0000;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.Ap td div {
  cursor: pointer;
}

.select-error-msg {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 0.02em;
  color: #ff0000;
}

.search-select .css-1s2u09g-control {
  width: 100%;
  height: 100% !important;
  -webkit-box-align: center;
  align-items: center;
  background: #ffffff !important;
  border-color: rgb(0, 0, 0) !important;
  border-radius: 50px !important;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em !important;
  font-family: 'Almarai', sans-serif;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 1vw;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  outline: 0px !important;
}

.css-8mmkcg {
  fill: black !important;
}

.css-tj5bde-Svg {
  display: none !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-1okebmr-indicatorSeparator {
  width: 0px !important;
  align-self: none !important;
}

@media only screen and (max-width: 1766px) {
  .dashboard-font {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.02em;
    color: #000000;
  }

  /* .modal-header .btn-close {
    border: 2px solid black !important;
    border-radius: 50% !important;
    padding: 0.8em;
    width: 0.4em !important;
    height: 0.5em !important;
    font-size: 0.5em !important;
    opacity: 0.8 !important;
    border-style: none;
    outline-style: none;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  } */

  .input-wrapper:before {
    content: "";
    position: absolute;
    left: 20px !important;
    top: 1% !important;
    bottom: 0;
    text-align: center;
    width: 16px !important;

    background: url("./icons/searchRole.svg") center / contain no-repeat;
  }

  .input-wrapper input {
    width: 100%;
    height: 3.5em;
    padding-left: 3em;
    padding-right: 3em;
    font-family: 'Almarai', sans-serif;
    outline: none;
    border: 1px solid #5E5E5E;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: 11px;
  }

  .role-search-btn {
    /* position: absolute; */
    width: 7em;
    height: 3em;
    /* margin-left:33vw;
    margin-top: 10%; */
    color: white;
    font-family: 'Almarai', sans-serif;
    background: #000000;
    border-radius: 50px;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    border-style: none;
    outline-style: none;
  }

  .city-select-btn {
    /* position: absolute; */
    width: 10em;
    height: 2.5em;
    /* margin-left:33vw;
      margin-top: 10%; */
    color: #00BD85;
    font-family: 'Almarai', sans-serif;
    background: #ffffff;
    border-radius: 50px;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    text-align: center;
    border: 1px solid #00BD85;
    outline-style: 1px solid #00BD85;
  }

  .add-role-btn {
    width: 11em;
    height: 3em;
    /* margin-top: 10%;
      margin-left: 80%; */
    background: #00BD85;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 12px;
    text-transform: capitalize;
    text-align: center;
    color: #FFFFFF;
    border-style: none;
    outline-style: none;
  }

  .add-role-btn p {
    margin-top: 0.9em;
    margin-left: 0.5em;
  }

  .role-table-header th {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }

  .role-table-text td {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    /* line-height: 2em; */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #5E5E5E;
    vertical-align: middle;
  }

  .role-edit-btn {
    width: 33%;
    height: 22%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
  }

  /* .staff-edit-btn{
      width: 43%;
      height: 22%;
      font-family: 'Almarai', sans-serif;
      color: #FFFFFF;
      background: #000000;
      border-radius: 25px;
      border-color: #000000;
      border-style: none;
      outline-style: none;
    } */
  .staff-edit-btn {
    width: 50%;
    height: 22%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
  }

  .reset-password-btn-staff {
    width: 60%;
    height: 22%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #4eddf0;
    border-radius: 25px;
    border-color: #4eddf0;
    border-style: none;
    outline-style: none;
    margin-left: 4px;
  }


  .form-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }

  .password-confirm-msg {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.02em;
    color: #ff0000;
  }

  .role-input {
    background: #ffffff;
    height: 2em;
    font-size: 0.9em;
    border-radius: 50px;
  }

  .form-select.AF-select {
    display: block;
    width: 100%;
    /* padding: .375rem 2.25rem .375rem .75rem; */
    line-height: 1em;
    -moz-padding-start: calc(0.75rem - 3px);
    background: #E5E5E5;
    height: 2em;
    font-size: 0.9em;
    background: url("./icons/arrow-down-green.svg");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 15px 15px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
  }

  .form-select.select-trade {
    display: block;
    width: 100%;
    /* padding: .375rem 2.25rem .375rem .75rem; */
    line-height: 1em;
    -moz-padding-start: calc(0.75rem - 3px);
    background: #E5E5E5;
    height: 2em;
    font-size: 0.9em;
    background: url("./icons/arrow-down-green.svg");
    background-repeat: no-repeat;
    background-position: left .75rem center;
    background-size: 15px 15px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
  }

  .AF-profile {
    position: absolute;
    top: 15em;
    left: 30%;
    right: 30%;
  }

  .notification {
    position: absolute;
    top: 20em;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .border-bottom {
    border-bottom: 3px dotted #5E5E5E;
  }

  .form-select.customize-select {
    display: block;
    width: 100%;
    /* padding: .375rem 2.25rem .375rem .75rem; */
    line-height: 1em;
    -moz-padding-start: calc(0.75rem - 3px);
    background: #E5E5E5;
    height: 2em;
    font-size: 0.9em;
    background: url("./icons/arrow-down-green.svg");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 15px 15px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
  }

  /* .css-1s2u09g-control{
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem!important;
    -moz-padding-start: calc(0.75rem - 3px);
    background: #E5E5E5!important;
    background-repeat: no-repeat!important;
    background-position: right .75rem center!important;
    background-size: 15px 15px!important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50px!important;
    font-family: 'Almarai', sans-serif;
    height: -30em!important;
    font-size: 0.9em!important;  
  }
  .css-tj5bde-Svg{
    display: none!important;
  }
  .css-1okebmr-indicatorSeparator{
    width: none!important;
  }
  .css-1hb7zxy-IndicatorsContainer{
    display: none!important;
  } */


  .add-role-table-header {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #5E5E5E;
  }

  .add-role-table-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }

  .role-done-btn {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 6em;
    height: 2.3em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  .role-close-btn {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 6em;
    height: 2.3em;
    background: #FF0000;
    border-color: #FF0000;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  /* .btn-close {
    border: 2px solid black !important;
    border-radius: 50% !important;
    width: 0.4em !important;
    height: 0.4em !important;
    font-size: 0.5em !important;
    opacity: 0.8 !important;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  } */

  .input-wrapper:before {
    content: "";
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("./icons/searchRole.svg") center / contain no-repeat;
  }

  .modal-content {
    width: 45em !important;
    border-radius: 39px;
    padding: 1em;
    margin-left: 17%;
    margin-bottom: 6em;
  }

  .modal-content.dashboard-modal {
    width: 45em !important;
    border-radius: 39px;
    padding: 1em;
    margin-left: 17%;
    margin-bottom: 6em;
    margin-top: 10%;
  }

  .select-error-msg {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.02em;
    color: #ff0000;
  }
}

.form-select.AF-select {
  display: block;
  width: 100%;
  padding: .355rem 2.25rem .355rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  /* line-height: 1.5; */
  background: #E5E5E5;
  /* color: #5E5E5E; */
  /* background-color: #fff; */
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 12px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;

}

.form-select.select-trade {
  display: block;
  width: 100%;
  padding: .355rem 2.25rem .355rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  /* line-height: 1.5; */
  background: #E5E5E5;
  /* color: #5E5E5E; */
  /* background-color: #fff; */
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left .75rem center;
  background-size: 12px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
}

.search-select .css-1s2u09g-control {
  width: 100%;
  height: 100% !important;
  -webkit-box-align: center;
  align-items: center;
  background: #ffffff !important;
  border-color: rgb(0, 0, 0) !important;
  border-radius: 50px !important;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em !important;
  font-family: 'Almarai', sans-serif;
  -webkit-box-pack: justify;
  justify-content: space-between;
  /* min-height: 1vw; */
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  outline: 0px !important;
}


@media only screen and (max-width: 1275px) {

  /* .input-wrapper {
    position: absolute;
    margin-left:15%;
    margin-top: 20%;
  } */
  .role-search-btn {
    /* position: absolute; */
    width: 7.5em;
    height: 3em;
    /* margin-left:43%;
    margin-top: 20%; */
    color: white;
    font-family: 'Almarai', sans-serif;
    background: #000000;
    border-radius: 50px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    border-style: none;
    outline-style: none;
  }

  .city-select-btn {
    /* position: absolute; */
    width: 7.5em;
    height: 3em;
    /* margin-left:33vw;
      margin-top: 10%; */
    color: #00BD85;
    font-family: 'Almarai', sans-serif;
    background: #ffffff;
    border-radius: 50px;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    text-align: center;
    border: 1px solid #00BD85;
    outline-style: 1px solid #00BD85;
  }

  .add-role-btn {
    width: 11em;
    height: 3em;
    /* margin-top: 20%;
      margin-left: 80%; */
    background: #00BD85;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 14px;
    text-transform: capitalize;
    text-align: center;
    color: #FFFFFF;
    border-style: none;
    outline-style: none;
  }

  .role-edit-btn {
    width: 50%;
    height: 22%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
  }

  /* .staff-edit-btn{
      width: 42%;
      height: 22%;
      font-family: 'Almarai', sans-serif;
      color: #FFFFFF;
      background: #000000;
      border-radius: 25px;
      border-color: #000000;
      border-style: none;
      outline-style: none;
    } */

  .form-select.AF-select {
    display: block;
    width: 100%;
    padding: .355rem 2.25rem .355rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    /* line-height: 1.5; */
    background: #E5E5E5;
    /* color: #5E5E5E; */
    /* background-color: #fff; */
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 12px 12px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
  }

  .form-select.select-trade {
    display: block;
    width: 100%;
    padding: .355rem 2.25rem .355rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    /* line-height: 1.5; */
    background: #E5E5E5;
    /* color: #5E5E5E; */
    /* background-color: #fff; */
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left .75rem center;
    background-size: 12px 12px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
  }

  .form-select.customize-select {
    display: block;
    width: 100%;
    /* padding: .375rem 2.25rem .375rem .75rem; */
    line-height: 1em;
    -moz-padding-start: calc(0.75rem - 3px);
    background: #E5E5E5;
    height: 2em;
    font-size: 0.9em;
    background: url("./icons/arrow-down-green.svg");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 15px 15px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
  }

  .select-error-msg {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.02em;
    color: #ff0000;
  }

  .input-wrapper:before {
    content: "";
    position: absolute;
    left: 20px !important;
    top: 1% !important;
    bottom: 0;
    text-align: center;
    width: 17px !important;

    background: url("./icons/searchRole.svg") center / contain no-repeat;
  }

  .input-wrapper input {
    width: 100%;
    height: 3.5em;
    padding-left: 3em;
    padding-right: 3em;
    font-family: 'Almarai', sans-serif;
    outline: none;
    border: 1px solid #5E5E5E;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: 11px;
  }

  /* .css-1s2u09g-control{
      width: 100%;
      padding: .375rem 2.25rem .375rem .75rem!important;
      -moz-padding-start: calc(0.75rem - 3px);
      background: #E5E5E5!important;
      background-repeat: no-repeat!important;
      background-position: right .75rem center!important;
      background-size: 15px 15px!important;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 50px!important;
      font-family: 'Almarai', sans-serif;
      height: 0.5em!important;
      font-size: 0.9em!important;
      align-content: center!important;
    
    }
    .css-tj5bde-Svg{
      display: none!important;
    }
    .css-1okebmr-indicatorSeparator{
      width: none!important;
    }
    .css-1hb7zxy-IndicatorsContainer{
      display: none!important;
    }     */
  .modal-content {
    width: 45em !important;
    border-radius: 39px;
    padding: 1em;
    margin-left: 17%;
    margin-bottom: 6em;
  }

  .modal-content.dashboard-modal {
    width: 45em !important;
    border-radius: 39px;
    padding: 1em;
    margin-left: 17%;
    margin-bottom: 6em;
    margin-top: 5%;
  }

}