.screen-otp-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 2.5rem;
    letter-spacing: 0.02em;
    color: #000000;
}

.screen-otp-phoneNumber {
    background: #E5E5E5;
    border-radius: 7px;
    font-size: 1rem;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.02em;
}

.screen-otp-submit-btn {
    background: #00BD85;
    border: 1px solid #00BD85;
    color: #fff;
    border-radius: 7px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 0.02em;
    padding-left: 0.6vw;
    padding-right: 0.6vw;
    padding-top: 0.3vw;
    padding-bottom: 0.3vw;
    outline: none;
    min-width: 10rem;
}
.screen-otp-submit-btn:hover{
    box-shadow: 7px 7px 7px #d2eee6;
}


.screen-otp--value-result {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Merriweather', serif;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.02em;
    margin-top: 3rem;
}

.screen-otp-error-msg{
    color: red;
    font-size: 1rem;
}
.screen-otp-value{
    color: #000000;
    font-size: 1.5rem;
}
.screen-otp-loading{
    color: #000000;
    font-size: 1rem;
}