.text-area-relation-comment{
    border-radius: 5px;
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 0.02em;
}
.compliance-status{
    font-weight: 500;
    font-size: 1.5rem;
    vertical-align: middle;
    letter-spacing: 0.02em;
    color: #000000;
}
/* .css-1pahdxg-control{
    height: 1px;
} */
