.collector-wallet {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #000000;
}

.collector-page-layout ul li .nav-link.active {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #00BD85;
}

.collector-page-layout ul li .nav-link {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #000000;
}

.collector-page-layout {
    position: absolute;
    left: 13%;
    width: 80%;
    top: 15%;
}

.Collector-details-container {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #000000;
    border-radius: 7px;
    background-color: rgb(246, 244, 247);
    padding: 1rem 1.5rem 1rem 1.5rem;
    line-height: 3rem;
}

.collector-details-icon-container {
    background-color: #e0e2e2;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 0.7rem;
}

.collector-details-icon {
    font-size: 1rem;
    color: #00BD85;
}

.collector-clickable-icon {
    cursor: pointer;
}