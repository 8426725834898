.login-container{
	max-width: 100%;
	max-height: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
}
.decorator{
	width: 3.5em;
}
.logo-img{
	width: 7em;
}
.sign-in-img{
	width: 95%;
}
.phone-label, .password-label{
	font-family: 'Almarai', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	letter-spacing: 0.02em;
	color: #000000;
}
.phone-btn, .password-btn{
	width: 90%;
	height: 60px;
	margin-top: 1em;
	background: #E5E5E5;
	border-radius: 50px;
	font-family: 'Almarai', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	/* line-height: 22px; */
	letter-spacing: 0.02em;
	color: #5E5E5E;
	padding-left: 3em;
	padding-right: 3em;
}
.submit-btn{
	width: 90%;
	height: 60px;
	/* margin-top: 2.5em; */
	background: #00BD85;
	border-radius: 50px;
	font-family: 'Almarai', sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 1.3vw;
	letter-spacing: 0.02em;
	color: #FFFFFF;
	border-style: none;
	outline-style: none;  
}
.pass-validity-msg{
	/* margin-top: 2.5em; */
	font-family: 'Almarai', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 19px;
	/* line-height: 22px; */
	letter-spacing: 0.02em;
	/* text-transform: capitalize; */
	color: #FF0000;
}



  /* 120% l fo2  */
  @media only screen and (max-width: 1597px) {
	.decorator{
		width: 3em;
	}
	.logo-img{
		width: 6.3em;
	}
	.phone-label, .password-label{
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		letter-spacing: 0.02em;
		color: #000000;
	}
	.phone-btn, .password-btn{
		width: 90%;
		height: 50px;
		margin-top: 1em;
		background: #E5E5E5;
		border-radius: 50px;
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		/* line-height: 22px; */
		letter-spacing: 0.02em;
		color: #5E5E5E;
		padding-left: 3em;
		padding-right: 3em;
	}
	.submit-btn{
		width: 90%;
		height: 50px;
		/* margin-top: 2.5em; */
		background: #00BD85;
		border-radius: 50px;
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: 800;
		font-size: 16px;
		letter-spacing: 0.02em;
		color: #FFFFFF;
		border-style: none;
		outline-style: none;  
	}
	.pass-validity-msg{
		/* margin-top: 2.5em; */
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		/* line-height: 22px; */
		letter-spacing: 0.02em;
		/* text-transform: capitalize; */
		color: #FF0000;
	}
	
	
  }


  /* 150 l fo2 */
  @media only screen and (max-width: 1300px) {
	.decorator{
		width: 2.5em;
	}
	.logo-img{
		width: 5em;
	}
	.phone-label, .password-label{
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		letter-spacing: 0.02em;
		color: #000000;
	}
	.phone-btn, .password-btn{
		width: 90%;
		height: 40px;
		margin-top: 1em;
		background: #E5E5E5;
		border-radius: 50px;
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		/* line-height: 22px; */
		letter-spacing: 0.02em;
		color: #5E5E5E;
		padding-left: 3em;
		padding-right: 3em;
	}
	.submit-btn{
		width: 90%;
		height: 40px;
		/* margin-top: 2.5em; */
		background: #00BD85;
		border-radius: 50px;
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: 800;
		font-size: 12px;
		letter-spacing: 0.02em;
		color: #FFFFFF;
		border-style: none;
		outline-style: none;  
	}
	.pass-validity-msg{
		/* margin-top: 2.5em; */
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		/* line-height: 22px; */
		letter-spacing: 0.02em;
		/* text-transform: capitalize; */
		color: #FF0000;
	}
	

	
  }







