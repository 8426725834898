.agel-navbar{
    width: 100%;
    height: 4.5em;
    top: 0px;
    position: fixed;
    z-index: 9999;
    background: #FFFFFF;
    box-shadow: 2px 2px 50px 10px #5e5e5e14;
    display: flex;
    align-items: center;

}
.nav-Btns{
    width: 50%;
    height: 60%;
    margin-left: 13%;  
    background: rgba(229, 229, 229, 0.2);
    border-radius: 54px;
  } 
  .page-name{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 1.1em;
    /* line-height: 1.7em;     */
    margin-top: 0.5em;

  }
  .page-name-wrapper, .page-name-wrapper:hover{
    text-decoration: none;
    width: 30%;
    height: 100%;
    border-radius: 54px;  
    text-align: center;
    color: #5E5E5E;
}
.logout-btn{
  margin-left: 25vw;
  cursor: pointer;
  opacity: 0.8;
}
.logout-btn:hover{
  margin-left: 25vw;
  cursor: pointer;
  opacity: 1;
}

/* .logout-btn{
  margin-left: 25%;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  font-weight: bold;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: rgb(152, 154, 160);
  border-radius: 25px;
  border-color: rgb(152, 154, 160);
  border-style: none;
  outline-style: none;

} */
@media only screen and (max-width: 1566px) {
    .agel-navbar{
    width: 100%;
    height: 3.5em;
    top: 0px;
    position: fixed;
    z-index: 9999;
    background: #FFFFFF;
    box-shadow: 2px 2px 50px 10px #5e5e5e14;
    display: flex;
    align-items: center;    
    }    
    .nav-Btns{
    width: 50%;
    height: 55%;
    background: rgba(229, 229, 229, 0.2);
    border-radius: 54px;
    margin-left: 15%;
  } 
  .page-name{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.9em;
    line-height: 1em;
    
  }
  .page-name-wrapper, .page-name-wrapper:hover{
    text-decoration: none;
    width: 30%;
    height: 100%;
    border-radius: 54px;  
    text-align: center;
    color: #5E5E5E;
}

.logout-btn{
  margin-left: 25vw;
  cursor: pointer;
  opacity: 0.8;
}
.logout-btn:hover{
  margin-left: 25vw;
  cursor: pointer;
  opacity: 1;
}

/* .logout-btn{
  margin-left: 25%;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: rgb(152, 154, 160);
  border-radius: 25px;
  border-color: rgb(152, 154, 160);
  border-style: none;
  outline-style: none;

} */

}
/* user profile css bs m4 3ayzeno nw
.nav-img{
    position: absolute;
    width: 60px;
    height: 60px;
    left: 68%;
    top: 61px;
}
.name{
    position: absolute;
    width: 257px;
    height: 22px;
    left: 73%;
    top: 76px;

    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #000000;
}
.description{
    position: absolute;
    width: 200px;
    height: 22px;
    left: 73%;
    top: 103px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #ABABAB;
}
.arrow-img{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 93.5%;
    top: 75px;
    cursor: pointer;
} */
