.collectors-table-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 50%; */
    font-size: 0.9rem;
    height: 22%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
    padding: 0.2rem 0.6rem;
}

.collectors-table-btns-icons {
    font-size: 1rem;
    margin-left: 0.2rem;
}

.retailer-info-in-view-modal {
    font-size: 1.1rem;
    font-family: 'Almarai', sans-serif;
    font-weight: 500;
    color: #000000;
    text-transform: capitalize;
}

.retailers-icons-in-view-modal {
    font-size: 1rem;
    margin-right: 0.5rem;
}

.collector-trash-icon {
    font-size: 1.5rem;
    color: red;
    cursor: pointer;
}

.collector-trash-icon-clicked {
    font-size: 1.5rem;
    color: rgb(196, 192, 192);
    cursor: progress;
}

.collectors-table-profile-details-icons {
    font-size: 1.5em;
}

.collectors-table-profile-details-icons:hover {
    cursor: pointer;
    border-radius: 50%;
    background-color: black;
    color: white;
}