.message-btn {
    padding: 1%;
    color: #000000;
    margin-right: 0.5em;
    margin-top: 0.1em;
    /*de el zadet b3d el tooltip*/
    cursor: pointer;
    font-size: 2vw;
}

.reinstate-btn {
    width: 30%;
    padding: 1%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #00BD85;
    border-radius: 25px;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
    margin-right: 1em
}

.cancel-retention-btn {
    width: 30%;
    padding: 1%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
}

.cancel-retention-modal-btn {
    width: 30%;
    padding: 1%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
}

.cancel-msg-modal-header {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3vw;
    letter-spacing: 0.02em;
    color: #000000;
}

.cancel-msg-modal-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #000000;
    margin-bottom: -0.9rem;
}

.cancelled-by {
    color: red;
    padding-left: 0.5vw;
    font-family: 'Merriweather', serif;
    font-weight: bold;
    text-transform: capitalize;
    text-decoration: underline;
    font-size: 1rem;
}

.reason-box {
    padding: 0.7vw;
    border: 1px solid #000000;
    border-radius: 15px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
}

.dasboardModal .modal-content {
    width: 30vw !important;
    border-radius: 39px;
    padding-top: 1.2vh;
    padding-bottom: 1.2vh;
    margin-left: 30%;
    /* margin-bottom: 30%; */
    margin-top: 20vh
}

.action-confirm-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3vw;
    letter-spacing: 0.02em;
    color: #000000;
}

.comments-loader {
    width: 25px;
    height: 25px;
    margin-left: 1em;
}

.comments-spinner-container {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2vw;
    letter-spacing: 0.02em;
    color: #fffb00;
}

@media only screen and (max-width: 1600px) {
    .reinstate-btn {
        width: 30%;
        padding: 0.8%;
        font-family: 'Almarai', sans-serif;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 25px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
        margin-right: 1em
    }

    .cancel-retention-btn {
        width: 30%;
        padding: 0.8%;
        font-family: 'Almarai', sans-serif;
        color: #FFFFFF;
        background: #000000;
        border-radius: 25px;
        border-color: #000000;
        border-style: none;
        outline-style: none;
    }

    .cancel-retention-modal-btn {
        width: 30%;
        padding: 1.7%;
        font-family: 'Almarai', sans-serif;
        color: #FFFFFF;
        font-size: 12px;
        background: #000000;
        border-radius: 25px;
        border-color: #000000;
        border-style: none;
        outline-style: none;
    }

}

.retention-comment-title {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}
.retention-comment-subtitle {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    color: #666464;
    margin-left: 0.5rem;

}