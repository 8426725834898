.clickable-row:hover td {
    cursor: pointer;
    background-color: rgba(182, 182, 182, 0.2);
    font-weight: bold;
    color: #00bd85;
}

.profile-img-setting-icon {
    font-style: normal;
    font-weight: 700;
    font-size: 5vw;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    border: 1px solid black;
    border-radius: 50%;
    padding: 1.4vw;
    cursor: pointer;
}

.multi-select-label-accounts.multiSelectContainer {
    background: #E5E5E5;
    width: 80%;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.multi-select-filters-accounts.multiSelectContainer {
    background: #ffffff;
    width: 100%;
    z-index: 999;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.8rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    /* border: 1px solid #5E5E5E; */
    border-style: none;
    outline-style: none;
}

.multi-select-filters-accounts.multiSelectContainer ul {
    /* max-height: 11vh; */
}

.accounts-date-filter {
    width: 100%;
    min-height: 2.3rem;
    margin-top: 0.4rem;
    z-index: 999;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.8rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #7c7c7c;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.profile-label-text {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.sms-msg-body {
    background-color: #e6e5f3;
    color: rgb(0, 0, 0);
    min-height: 13.5em;
    border-radius: 25px;
    padding: 1vw;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9vw;
    word-spacing: 0.3rem;
    line-height: 1.5rem;
}

/* li.msg-content{
    word-spacing: 0.3rem;
    line-height: 1.5rem;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9vw;
} */
.page-link:focus {
    box-shadow: 0 0 0 0 rgb(13 110 253 / 25%);
}

.pagination {
    display: flex;
    justify-content: center;
}

.retailer-table-pagination .page-link,
.wholesaler-table-pagination .page-link {
    position: relative;
    display: block;
    color: #0d6efd;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-radius: 20px;
    width: 1rem;
    height: 1.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    align-items: center;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.retailer-table-pagination .page-link:hover,
.wholesaler-table-pagination .page-link:hover {
    color: #ffffff;
    background-color: #0d6efd;
}

.sync-btn-progress-behavior,
.sync-btn-pointer-behavior,
.download-btn-pointer-behavior,
.download-btn-progress-behavior {
    font-size: 2.4rem;
    margin-left: 0.5rem;
}

.sync-btn-pointer-behavior:hover,
.download-btn-pointer-behavior:hover {
    cursor: pointer;
}

.sync-btn-progress-behavior:hover,
.download-btn-progress-behavior:hover {
    cursor: progress;
}