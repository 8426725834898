.tableCard {
  width: 10rem;
  line-height: 0.8em;
  line-height: 1em;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.2);
  /* this adds the "card" effect */
  border-radius: 30px;
}

.cardTitle {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.cardText {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 0.5em;
  margin-top: 1em;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
}

.pending-btn {
  /* width: 33%; */
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #00BD85;
  border-color: #00BD85;
  border-style: none;
  outline-style: none;
  font-size: 15px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.modal-header-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.modal-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.7em;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.confirm-msg-modal-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.AP-modal-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.7em;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
}

.AP-modal-user-type-radio {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.basic-info-input {
  position: absolute;
  width: 500px;
  height: 70px;
  left: 741px;
  top: 816px;

  background: #E5E5E5;
  border-radius: 50px;
}

.profile-td {
  width: 200px;
}

.discount-btn-1 {
  background: #7952B3;
  border: 1px solid #7952B3;
  box-sizing: border-box;
  border-radius: 50px;
  /* width: 220px;
height: 40px; */
}

.action-btns-in-profiles {
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8vw;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  outline: none;
  min-width: 60%;
  /* min-height: 50%; */
}

.retailer-profile-logs, .ws-profile-logs {
  height: 2.5rem;
  width: 100%;
  border-radius: 10px;
  border: none;
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  text-transform: capitalize;
  opacity: 0.9;
}

.change-password-btn {

  background: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}

.change-password-btn:hover {

  background: #000000;
  border: 1px solid #000000;
  color: #fff;
}

.delete-btn-in-profile {
  background: #ffffff;
  border: 1px solid #ff0000;
  color: #ff0000;
}

.delete-btn-in-profile:hover {
  background: #ff0000;
  border: 1px solid #ff0000;
  color: #fff;
}

.save-basic-info {
  background: #00BD85;
  border: 1px solid #00BD85;
  color: #ffffff;
}

.save-basic-info:hover {
  background: #00BD85;
  border: 1px solid #00BD85;
  color: #ffffff;
}

.PIN {
  background: #fff;
  border: 1px solid #56bbff;
  border-radius: 50px;
  color: #56bbff;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8vw;
  width: 60%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  outline: none;
}

.PIN:hover {
  background: #56bbff;
  border: 1px solid #56bbff;
  border-radius: 50px;
  color: #fff;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8vw;
  width: 60%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  outline: none;
}

.pin-err-msg {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-size: 0.8rem;
  font-weight: 700;
  color: red;
}

.generate-otp-in-profile {
  background: #ffffff;
  border: 1px solid #ab13ca;
  color: #ab13ca;
}

.generate-otp-in-profile:hover {
  background: #ab13ca;
  border: 1px solid #ab13ca;
  color: #fff;
}

.otp-icon {
  margin-right: 0.3rem;
  font-size: 1.1rem;
  color: #000000;
}

.otp-value {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  background: #E5E5E5;
  border-radius: 50px;
  padding: 0.2rem 2.5rem 0.2rem 2.5rem;
}

.otp-value-error-msg {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ff0000;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  text-align: center;
}

.retailerTrippleToggleSwitchText,
.wsToggleSwitchText {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 0.8vw;
  text-align: center;
  margin-right: 0.3rem;
  /* min-width: 50%;  
  max-width: 50%; */
}

.retailerTrippleToggleSwitch .css-1dfzgwp .css-aj9s48 {
  background-color: red;
}

.retailerTrippleToggleSwitch .css-1dfzgwp .css-65zkr6 {
  background-color: #e3f305;
}

.retailerTrippleToggleSwitch .css-1dfzgwp .css-oihcde {
  background-color: #00BD85;
}

.notification-btn, .sms-btn {
  background-color: #01bd85;
  color: #ffffff;
  border: 0px solid #00BD85;
}
.accounts-table-action-btns{
  box-sizing: border-box;
  border-radius: 50px;
  padding: 0.5rem 0.9rem;
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.apply-filter {

  box-sizing: border-box;
  border-radius: 50px;
  width: 120px;
  height: 40px;
  background: #00BD85;
  border: 1px solid #00BD85;
}

.cancel-basic-info {
  background: #fff;
  border: 1px solid #ff0000;
  border-radius: 50px;
  color: #ff0000;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8vw;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  outline: none;
}

.cancel-basic-info:hover {
  background: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 50px;
  color: #ffffff;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8vw;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  outline: none;
}

.cancel-visit {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #ff0000;
  border-color: #ff0000;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.action-visit {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.save-edits {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #0071bd;
  border-color: #0071bd;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.reviewed-btn-ws-activation-form {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 11em;
  height: 2.7em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 0.5rem;
}

.Assignment-accepted {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 11em;
  height: 2.7em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.revert-visit {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #F4B844;
  border-color: #F4B844;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.revert-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.revertImg {
  width: 12em;
}

.revertModal .modal-content {
  width: 50em !important;
  border-radius: 39px;
  padding: 1em;
  margin-bottom: 25%;
}


.role-Ap-input.form-control[readonly] {
  background: #ffffff;
  border-color: #000000;
  border-radius: 50px;
}

.Ap-logs {
  border-left: 2px solid #00BD85;
}

.logs-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.logs-data {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
  margin-right: 1em;
}

.Ap-logs .info {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
  margin-top: -1em;
}

.retailer-Ap-text-area {
  border-radius: 20px;
  padding: 25px;
  margin-bottom: 6vh;
  background-color: #FFFFFF !important;
}

.success-modal-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;

}

.server-msg-modal .modal-content {
  min-height: 15em;
}

/* .server-msg-modal .modal-header .btn-close {
  padding: 0.7em;
} */

.Ap-table-layout {
  position: absolute;
  left: 13%;
  width: 120em !important;
  top: 37%;
  overflow: auto;

}

.Ap-table-layout td,
.Ap-table-layout tbody,
.Ap-table-layout td,
.Ap-table-layout tfoot,
.Ap-table-layout th,
.Ap-table-layout thead,
.Ap-table-layout tr {
  border-style: none !important;
}

.scroll-table {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
  z-index: 99
}

.scroll-table thead th {
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: white;
  bottom: 3em;
  padding-bottom: 2em;
}

.retailer-Ap-img {
  height: 14.5vh
}

.reschedule-modal .modal-content {
  width: 35em !important;
}

.img-icon {
  /* margin-top: 0.7em; */
}

.img-name {
  margin-top: 0.8vh;
  margin-left: 0.8em;
}

.download-icon {
  margin-top: 0.3em;
  cursor: pointer;
}

.loader {
  margin-right: 0.3rem;
  margin-top: 0.3rem;
  width: 1rem;
  height: 1rem;
  cursor: wait;
}

.download-contract {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  width: 10em;
  height: 2.2em;
  background: #002fff;
  border-color: #002fff;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  /* margin-left: 100%; */
}

.Ap-register-image {
  height: 100%;
  height: 100%;
  /* height:4em */
}

.cancelation-label-form {
  font-size: calc(.1rem + 1vw);
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: #000000;
}

/* 120% l7d zoom in 150% */
@media only screen and (max-width: 1597px) {
  .tableCard {
    width: 8.5rem !important;
    /* height: 9.5rem!important; */
    line-height: 0.8em !important;
    border-radius: 30px !important;
  }

  .scroll-table {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
    z-index: 99;
    /* padding-right: 11em!important; */
  }

  .cardTitle {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    margin-right: 5em !important;
  }

  .role-Ap-input {
    height: 2em;
    font-size: 0.9em;
    background: #ffffff;
    border-color: #000000;
    border-radius: 50px;
  }

  .pending-btn {
    /* width: 33%; */
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #00BD85;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
    font-size: 11px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .cancel-visit {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #ff0000;
    border-color: #ff0000;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .action-visit {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  .save-edits {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #0071bd;
    border-color: #0071bd;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  .reviewed-btn-ws-activation-form {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 10em;
    height: 2.7em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 0.5rem;
  }

  .Assignment-accepted {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 10em;
    height: 2.7em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  .revert-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }

  .revertImg {
    width: 12em;
  }

  .retailer-Ap-text-area {
    border-radius: 20px;
    padding: 3px;
    margin-bottom: 9.2vh;
    background-color: #FFFFFF !important;
  }

  .retailer-Ap-img {
    height: 13.5vh;
  }

  .Ap-table-layout {
    position: absolute;
    left: 13%;
    width: 115em !important;
    top: 37%;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  .reschedule-modal .modal-content {
    width: 30em !important;
  }

  .revertModal .modal-content {
    width: 25em !important;
    border-radius: 39px;
    padding: 1em;
    margin-left: 30%;
    margin-bottom: 35%;
  }

  .img-icon {
    margin-top: 0.7em;
  }

  .img-name {
    margin-top: 3vh;
    margin-left: 0.8em;
  }

  .download-icon {
    margin-top: 0.9em;
    cursor: pointer;
  }

  .download-contract {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    width: 17em;
    height: 2.2em;
    background: #002fff;
    border-color: #002fff;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .Ap-register-image {
    width: 100%;
    height: 100%;
  }
}

/* 110% l7d zoom out ab3d */
@media only screen and (min-width: 1598px) {
  .scroll-table {
    overflow-y: scroll;
    overflow-x: hidden !important;
    height: 500px;
    z-index: 99;
    /* padding-right: 13em!important; */
  }

  .Ap-table-layout {
    position: absolute;
    left: 13%;
    width: 127em !important;
    top: 37%;
    overflow-x: hidden !important;
    overflow-y: hidden !important;

  }

  /* .firstTeam-btn{
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #84e4f5;
    border-color: #84e4f5;
    border-style: none;
    outline-style: none;
    font-size: 10px!important;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  } */

}

.activation-process-loading-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.02em;
  color: #000000;
}


.AP-user-type-select-box .css-1s2u09g-control,
.AP-user-type-select-box .css-b62m3t-container,
.AP-user-type-select-box .css-1pahdxg-control,
.AP-user-type-select-box .css-lgtuOrj-indicatorContainer {
  background: #e5e5e5de;
  border-color: #e5e5e5de;
  /* height: 3em; */
  font-size: 0.8rem;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  border-radius: 7px;

}

.assign-user-type {
  cursor: progress;
}