/* .fixed-navbar {
  background-color: white;
  position: fixed;
} */
.create-lead-btn-positioning{
  margin-left: 22rem;
  position: fixed;
  z-index: 999;
}

.acquisition-date-flex-label {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 0.6rem;
  margin-right: 0.4rem;
}

.acquisition-date {
  background: white;
  width: 68%;
  height: 100%;
  font-size: 0.9em;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  border-radius: 50px;
  border-color: #000000;
}

.create-lead-btn {
  /* position: absolute; */
  width: 7em;
  height: 3em;
  /* margin-left:33vw;
  margin-top: 10%; */
  color: white;
  font-family: 'Almarai', sans-serif;
  background: #c01313;
  border-radius: 50px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  border-style: none;
  outline-style: none;
}

.acquisition-create-lead-labels {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
  width: 50%;
}

.acquisition-create-lead-inputs {
  /* background: #E5E5E5; */
  width: 60%;
  height: 2.3rem;
  font-size: 0.9em;
  border-radius: 5px;
  margin-left: 1rem;
  border-style: none;
  outline: none;
}

.acquisition-create-lead-inputs.form-control:focus {
  color: #212529;
  border-color: none;
  outline: 0;
  box-shadow: none;
  box-shadow: 0 0 0 0.25rem rgb(228, 231, 231);
}

.acquisition-modals-containers {
  padding-left: 1.2rem;
  padding-right: 1rem;
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;
  border-radius: 20px;
  min-height: 15rem;
  text-align: justify;
}

.acquisition-create-lead-select,
.acquisition-create-lead-select:hover {
  width: 60% !important;
  height: 2rem !important;
  font-size: 0.9em;
  border-radius: 5px;
  margin-left: 1rem;
  border-style: none !important;
  border-color: rgb(255, 255, 255) !important;
  outline: 0px !important;
  align-items: center;
  background: #ffffff !important;
  font-family: 'Almarai', sans-serif;
  margin-top: -0.3rem;
}

.acquisition-create-lead-select .css-1s2u09g-control {
  border-color: rgb(255, 255, 255) !important;
  border-style: none !important;
  outline: 0px !important;

}

.acquisition-modal-action-btn {
  border-radius: 7px;
  color: #ffffff;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9em;
  opacity: 1;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  outline: none;
}

.acquisition-modal-action-btn:hover {
  border-radius: 7px;
  color: #ffffff;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9em;
  opacity: 0.8;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  outline: none;

}

.acquisition-discard-btn {
  background: rgb(212, 212, 13);
  border: 1px solid rgb(212, 212, 13);
  margin-left: 0.5rem;
}

.acquisition-edit-btn {
  background: #0087bd;
  border: 1px solid #0087bd;
}

.acquisition-delete-btn {
  background: rgb(255, 0, 0);
  border: 1px solid rgb(255, 0, 0);
}

.acquisition-close-btn {
  background: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
}

.acquisitionModal {
  margin-top: 5rem;
}

.history-container {
  display: flex;
  flex-flow: wrap;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 0.9em;
  letter-spacing: 0.02em;
  color: rgb(0, 0, 0);
  background-color: rgb(247, 185, 52);
  margin-top: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  width: 80%;
  border-radius: 3px;
}

.acquisitionComment:placeholder-shown,
.acquisitionComment:not(:placeholder-shown) {
  border-radius: 5px;
  border: 3px solid black;
  background-color: rgb(255, 246, 166);
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-size: 0.9rem;
  letter-spacing: 0.02em;
  box-shadow: none;
}

.acquisition-confirm-btn {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  background: #ccd4da;
  border: 2px solid black;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.acquisition-card {

  max-width: 90%;
  min-height: 6rem;
  line-height: 1em;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  text-align: center;
  margin-top: 1rem;
  color: rgb(0, 0, 0);
  /* background-color: rgb(240, 232, 220); */
}

.cardText {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 550;
  line-height: 1.3rem;
  /* margin-top: 1em; */
  font-size: 0.7rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.acquisition-table-titles-border {
  border: 2px solid rgb(0, 0, 0);
  padding: 0.5rem 0.5rem;
  border-radius: 7px;
}

/* .crm-table td,
.crm-table tbody,
.crm-table td,
.crm-table tfoot,
.crm-table th,
.crm-table thead
 {
  border-style: none !important;
}
.crm-table thead tr th{
  border: 3px solid black !important;
  padding: 0.2rem 0.5rem -1rem 0.5rem!important;
  border-radius: 7px;
}
.crm-table thead{
 background-color: #c01313!important;
 position: sticky;
 top: 0;
 z-index: 99;
 background-color: white;
 border: 3px solid black !important;
 padding: 0.2rem 0.5rem -1rem 0.5rem!important;
 border-radius: 7px;

} */
.Ap-table-layout.acquisition-table th {
  border: 2px solid rgb(0, 0, 0) !important;
  padding: 0.5rem 0.5rem !important;
  border-radius: 70px !important;
  margin-bottom: 29rem !important;
}