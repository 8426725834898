.agel-sidebar{
  width: 12%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: #FFFFFF;
  box-shadow: 2px 2px 50px 10px rgba(94, 94, 94, 0.08);
  text-align: center;
  overflow-x: scroll;
}
.sidebar-img{
  width: 80%;
  height: 15%;
  margin-bottom: 1em;
}
.li-wrapper{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 12%;
}
.link{
  text-decoration: none;
  width: 100%;
}
.sidebar-title{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1em;
  text-decoration: none;
  color: #000000;
  margin-left: 0.6em;
  margin-right: 0.6em;
  margin-top: 1.5em;
  /* text-align: center; */

}
.sidebar-sub-title{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1em;
  text-decoration: none;
  margin-left: 3em;
  line-height: 35px;
  text-align: center;
  color: #ABABAB;
  cursor: pointer;
  text-align: left;
}

.sidebar-li-img{
  height: 2.7vh;
  margin-top: 2.7vh;
  margin-left: 1vw;
  text-align: left;
}
.arrow{
  height: 2vh;
  margin-right: 7%;
  /* margin-top: 2vh; */
  /* text-align: right; */
}
@media only screen and (max-width: 1566px) {
  .sidebar-title{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9em;
    text-decoration: none;
    color: #000000;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-top: 1.3em;
  }
  .sidebar-li-img{
    height: 2.7vh;
    margin-top: 2.7vh;
    margin-left: 1vw;
    text-align: left;
    }
  .arrow{
    height: 2vh;
    margin-right: 5%;
    /* margin-top: 3vh;
    text-align: right; */
    }
    .sidebar-sub-title{
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 0.7em;
      text-decoration: none;
      margin-left: 3.5em;
      line-height: 25px;
      text-align: center;
      color: #ABABAB;
      cursor: pointer;
      text-align: left;
    }
    
}