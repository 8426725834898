@import url(https://fonts.googleapis.com/css2?family=Lobster&family=Merriweather:ital,wght@1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-container{
	max-width: 100%;
	max-height: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
}
.decorator{
	width: 3.5em;
}
.logo-img{
	width: 7em;
}
.sign-in-img{
	width: 95%;
}
.phone-label, .password-label{
	font-family: 'Almarai', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	letter-spacing: 0.02em;
	color: #000000;
}
.phone-btn, .password-btn{
	width: 90%;
	height: 60px;
	margin-top: 1em;
	background: #E5E5E5;
	border-radius: 50px;
	font-family: 'Almarai', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	/* line-height: 22px; */
	letter-spacing: 0.02em;
	color: #5E5E5E;
	padding-left: 3em;
	padding-right: 3em;
}
.submit-btn{
	width: 90%;
	height: 60px;
	/* margin-top: 2.5em; */
	background: #00BD85;
	border-radius: 50px;
	font-family: 'Almarai', sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 1.3vw;
	letter-spacing: 0.02em;
	color: #FFFFFF;
	border-style: none;
	outline-style: none;  
}
.pass-validity-msg{
	/* margin-top: 2.5em; */
	font-family: 'Almarai', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 19px;
	/* line-height: 22px; */
	letter-spacing: 0.02em;
	/* text-transform: capitalize; */
	color: #FF0000;
}



  /* 120% l fo2  */
  @media only screen and (max-width: 1597px) {
	.decorator{
		width: 3em;
	}
	.logo-img{
		width: 6.3em;
	}
	.phone-label, .password-label{
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		letter-spacing: 0.02em;
		color: #000000;
	}
	.phone-btn, .password-btn{
		width: 90%;
		height: 50px;
		margin-top: 1em;
		background: #E5E5E5;
		border-radius: 50px;
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		/* line-height: 22px; */
		letter-spacing: 0.02em;
		color: #5E5E5E;
		padding-left: 3em;
		padding-right: 3em;
	}
	.submit-btn{
		width: 90%;
		height: 50px;
		/* margin-top: 2.5em; */
		background: #00BD85;
		border-radius: 50px;
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: 800;
		font-size: 16px;
		letter-spacing: 0.02em;
		color: #FFFFFF;
		border-style: none;
		outline-style: none;  
	}
	.pass-validity-msg{
		/* margin-top: 2.5em; */
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		/* line-height: 22px; */
		letter-spacing: 0.02em;
		/* text-transform: capitalize; */
		color: #FF0000;
	}
	
	
  }


  /* 150 l fo2 */
  @media only screen and (max-width: 1300px) {
	.decorator{
		width: 2.5em;
	}
	.logo-img{
		width: 5em;
	}
	.phone-label, .password-label{
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		letter-spacing: 0.02em;
		color: #000000;
	}
	.phone-btn, .password-btn{
		width: 90%;
		height: 40px;
		margin-top: 1em;
		background: #E5E5E5;
		border-radius: 50px;
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		/* line-height: 22px; */
		letter-spacing: 0.02em;
		color: #5E5E5E;
		padding-left: 3em;
		padding-right: 3em;
	}
	.submit-btn{
		width: 90%;
		height: 40px;
		/* margin-top: 2.5em; */
		background: #00BD85;
		border-radius: 50px;
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: 800;
		font-size: 12px;
		letter-spacing: 0.02em;
		color: #FFFFFF;
		border-style: none;
		outline-style: none;  
	}
	.pass-validity-msg{
		/* margin-top: 2.5em; */
		font-family: 'Almarai', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		/* line-height: 22px; */
		letter-spacing: 0.02em;
		/* text-transform: capitalize; */
		color: #FF0000;
	}
	

	
  }








.agel-navbar{
    width: 100%;
    height: 4.5em;
    top: 0px;
    position: fixed;
    z-index: 9999;
    background: #FFFFFF;
    box-shadow: 2px 2px 50px 10px #5e5e5e14;
    display: flex;
    align-items: center;

}
.nav-Btns{
    width: 50%;
    height: 60%;
    margin-left: 13%;  
    background: rgba(229, 229, 229, 0.2);
    border-radius: 54px;
  } 
  .page-name{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 1.1em;
    /* line-height: 1.7em;     */
    margin-top: 0.5em;

  }
  .page-name-wrapper, .page-name-wrapper:hover{
    text-decoration: none;
    width: 30%;
    height: 100%;
    border-radius: 54px;  
    text-align: center;
    color: #5E5E5E;
}
.logout-btn{
  margin-left: 25vw;
  cursor: pointer;
  opacity: 0.8;
}
.logout-btn:hover{
  margin-left: 25vw;
  cursor: pointer;
  opacity: 1;
}

/* .logout-btn{
  margin-left: 25%;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  font-weight: bold;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: rgb(152, 154, 160);
  border-radius: 25px;
  border-color: rgb(152, 154, 160);
  border-style: none;
  outline-style: none;

} */
@media only screen and (max-width: 1566px) {
    .agel-navbar{
    width: 100%;
    height: 3.5em;
    top: 0px;
    position: fixed;
    z-index: 9999;
    background: #FFFFFF;
    box-shadow: 2px 2px 50px 10px #5e5e5e14;
    display: flex;
    align-items: center;    
    }    
    .nav-Btns{
    width: 50%;
    height: 55%;
    background: rgba(229, 229, 229, 0.2);
    border-radius: 54px;
    margin-left: 15%;
  } 
  .page-name{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.9em;
    line-height: 1em;
    
  }
  .page-name-wrapper, .page-name-wrapper:hover{
    text-decoration: none;
    width: 30%;
    height: 100%;
    border-radius: 54px;  
    text-align: center;
    color: #5E5E5E;
}

.logout-btn{
  margin-left: 25vw;
  cursor: pointer;
  opacity: 0.8;
}
.logout-btn:hover{
  margin-left: 25vw;
  cursor: pointer;
  opacity: 1;
}

/* .logout-btn{
  margin-left: 25%;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: rgb(152, 154, 160);
  border-radius: 25px;
  border-color: rgb(152, 154, 160);
  border-style: none;
  outline-style: none;

} */

}
/* user profile css bs m4 3ayzeno nw
.nav-img{
    position: absolute;
    width: 60px;
    height: 60px;
    left: 68%;
    top: 61px;
}
.name{
    position: absolute;
    width: 257px;
    height: 22px;
    left: 73%;
    top: 76px;

    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #000000;
}
.description{
    position: absolute;
    width: 200px;
    height: 22px;
    left: 73%;
    top: 103px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #ABABAB;
}
.arrow-img{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 93.5%;
    top: 75px;
    cursor: pointer;
} */

.agel-sidebar{
  width: 12%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: #FFFFFF;
  box-shadow: 2px 2px 50px 10px rgba(94, 94, 94, 0.08);
  text-align: center;
  overflow-x: scroll;
}
.sidebar-img{
  width: 80%;
  height: 15%;
  margin-bottom: 1em;
}
.li-wrapper{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 12%;
}
.link{
  text-decoration: none;
  width: 100%;
}
.sidebar-title{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1em;
  text-decoration: none;
  color: #000000;
  margin-left: 0.6em;
  margin-right: 0.6em;
  margin-top: 1.5em;
  /* text-align: center; */

}
.sidebar-sub-title{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1em;
  text-decoration: none;
  margin-left: 3em;
  line-height: 35px;
  text-align: center;
  color: #ABABAB;
  cursor: pointer;
  text-align: left;
}

.sidebar-li-img{
  height: 2.7vh;
  margin-top: 2.7vh;
  margin-left: 1vw;
  text-align: left;
}
.arrow{
  height: 2vh;
  margin-right: 7%;
  /* margin-top: 2vh; */
  /* text-align: right; */
}
@media only screen and (max-width: 1566px) {
  .sidebar-title{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9em;
    text-decoration: none;
    color: #000000;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-top: 1.3em;
  }
  .sidebar-li-img{
    height: 2.7vh;
    margin-top: 2.7vh;
    margin-left: 1vw;
    text-align: left;
    }
  .arrow{
    height: 2vh;
    margin-right: 5%;
    /* margin-top: 3vh;
    text-align: right; */
    }
    .sidebar-sub-title{
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 0.7em;
      text-decoration: none;
      margin-left: 3.5em;
      line-height: 25px;
      text-align: center;
      color: #ABABAB;
      cursor: pointer;
      text-align: left;
    }
    
}
html {
  background-color: white;
}

.agelFontFamily {
  font-family: 'Almarai', sans-serif;
}

.table>:not(:first-child) {
  border-top: 2px solid;
}

.table>tbody>tr>td {
  vertical-align: middle !important;
}

.table {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2) !important;
}

/* de 34an yseb el tr ttlwan w2t may3ml hover 3l row f el accounts table wba2e el 7agat el mo4bha */
/* .table {
  --bs-table-bg: none!important;
} */

.expand-screen-layout {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1em;
  text-transform: capitalize;
  text-align: center;
  margin-top: 45vh;
  color: #00BD85;
  overflow-y: hidden;
  overflow-x: hidden;
}

.dashboard-font {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}

.dashboard-error-msg {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  display: flex;
  justify-content: start;
  align-items: center;
  letter-spacing: 0.02em;
  color: #FF0000;
}

.expand-screen-layout span {
  margin-left: 0.5em;
  margin-top: -2.9vh;
}

.rubberBand {
  animation: rubberBand;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 3s;
  animation-iteration-count: infinite;
  /* animate-repeat: 2 */
}

.layout-top {
  position: absolute;
  width: 80%;
  margin-left: 13%;
  margin-top: 6%;
}

.layout-row-2 {
  position: absolute;
  width: 80%;
  margin-left: 13%;
  margin-top: 9.8%;
}

.layout-row-3 {
  position: absolute;
  width: 80%;
  margin-left: 13%;
  margin-top: 14.2%;
}

.tables-layout {
  position: absolute;
  left: 13%;
  width: 80%;
  top: 37%;
}

.profile-page-layout {
  position: absolute;
  left: 13%;
  width: 80%;
  top: 8%;
}

/* .input-wrapper {
    position: absolute;
    margin-left:15%;
    margin-top: 10%;
  } */
.frame {
  position: relative;

  width: 193px;
  height: 185px;

  /* background-image:url('public/images/Ellipse-388.png'); */
  /* background-image:linear-gradient(red, yellow, green); */
  background-position: center;
  background-size: cover;
}

.centered-image {
  position: absolute;
  /* left: 50%;
    top: 50%;
    right: 50%;
    bottom: 50%; */

  display: block;
  margin: auto;
}

.span-profile {
  display: block;
  font-size: 1vw;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
}

.input-wrapper:before {
  content: "";
  position: absolute;
  left: 2%;
  top: 1%;
  text-align: center;
  bottom: 0;
  width: 22px;
  background: url(/static/media/searchRole.ef599069.svg) center / contain no-repeat;
}

.input-wrapper input {
  width: 100%;
  height: 3em;
  padding-left: 3em;
  padding-right: 3em;
  font-family: 'Almarai', sans-serif;
  outline: none;
  border: 1px solid #5E5E5E;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 15px;

}

.role-search-btn {
  /* position: absolute; */
  width: 7em;
  height: 3em;
  /* margin-left:33vw;
  margin-top: 10%; */
  color: white;
  font-family: 'Almarai', sans-serif;
  background: #000000;
  border-radius: 50px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  border-style: none;
  outline-style: none;
}

.add-role-btn {
  width: 15em;
  height: 3em;
  /* margin-top: 10%;
    margin-left: 80%; */
  background: #00BD85;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  text-transform: capitalize;
  text-align: center;
  color: #FFFFFF;
  border-style: none;
  outline-style: none;
}

.city-select-btn {
  /* position: absolute; */
  width: 7em;
  height: 3em;
  /* margin-left:33vw;
  margin-top: 10%; */
  color: #00BD85;
  font-family: 'Almarai', sans-serif;
  background: #ffffff;
  border-radius: 50px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  text-align: center;
  border: 1px solid #00BD85;
  outline-style: 1px solid #00BD85;
  /* border-style: none; */
}

.add-role-btn p {
  margin-top: 0.7em;
  margin-left: 0.5em;
  font-size: 1.1em;
}

.add-role-btn-icon {
  margin-top: -2px;
}

.role-edit-btn {
  width: 50%;
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #000000;
  border-radius: 25px;
  border-color: #000000;
  border-style: none;
  outline-style: none;
}

.staff-edit-btn {
  width: 50%;
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #000000;
  border-radius: 25px;
  border-color: #000000;
  border-style: none;
  outline-style: none;
}

.reset-password-btn-staff {
  width: 50%;
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #4eddf0;
  border-radius: 25px;
  border-color: #4eddf0;
  border-style: none;
  outline-style: none;
  margin-left: 4px;
}

.agent-reset-btn {
  width: 50%;
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #4eddf0;
  border-radius: 25px;
  border-color: #4eddf0;
  border-style: none;
  outline-style: none;
  margin-left: 4px;
}

.agent-edit-btn {
  width: 50%;
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #000000;
  border-radius: 25px;
  border-color: #000000;
  border-style: none;
  outline-style: none;
  margin-left: 4px;
}


.role-table-header th {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

/* .modal-header .btn-close {
  border: 2px solid black !important;
  border-radius: 50% !important;
  padding: 0.5vw;
  font-size: 0.6vw !important;
  opacity: 0.8 !important;
  border-style: none;
  outline-style: none;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
} */

.modal-content {
  width: 50em !important;
  border-radius: 39px;
  padding: 1em;
  margin-bottom: 6em;
}

.role-table-text td {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 2em; */
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #5E5E5E;
  vertical-align: middle;
}

.form-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 22px; */
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.form-select.AF-select {
  display: block;
  width: 100%;
  padding: .355rem 2.25rem .355rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  /* line-height: 1.5; */
  background: #E5E5E5;
  /* color: #5E5E5E; */
  /* background-color: #fff; */
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 12px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
}

.form-select.select-trade {
  display: block;
  width: 100%;
  padding: .355rem 2.25rem .355rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  /* line-height: 1.5; */
  background: #E5E5E5;
  /* color: #5E5E5E; */
  /* background-color: #fff; */
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left .75rem center;
  background-size: 12px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
}

.form-select.customize-select {
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  /* line-height: 1.5; */
  background: #E5E5E5;
  /* color: #5E5E5E; */
  /* background-color: #fff; */
  background: url(/static/media/arrow-down-green.fa55c8d9.svg);
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 19px 19px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
}

.agent-select {
  display: block;
  width: 100%;
  padding: .355rem 2.25rem .355rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  /* line-height: 1.5; */
  background: #E5E5E5;
  /* color: #5E5E5E; */
  /* background-color: #fff; */
  background: url(/static/media/downArrowBlack.1286ad9d.svg);
  background-repeat: no-repeat;
  background-position: left .75rem center;
  background-size: 12px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
}


.password-confirm-msg {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 0.02em;
  color: #ff0000;
}

.role-input {
  background: #ffffff;
  height: 3em;
  font-size: 0.9em;
  border-radius: 50px;
}

/* check box shape */
.status input {
  background: #E5E5E5;
  border-radius: 50px;
  width: 1.1em;
  height: 1.1em;
  outline: none;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E");
}

.status input:checked {
  border-color: #00BD85;
  background-color: #00BD85;
}

.side-bar-profile {
  border-right: 1px solid gray;
}

.side-bar-note-profile {
  background-color: #E5E5E5;
  border-radius: 50px;
  height: auto;
  width: auto;

}

.side-bar-note-profile-span {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 25px;
  height: auto;
  width: auto;
  padding: 0.6em;

  font-family: 'Almarai';
  font-style: normal;
  font-weight: 510;
  font-size: 12px;
  line-height: 25px;
  text-transform: capitalize;

}

.side-bar-note-add-note-span {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 18px;
  text-transform: capitalize;
  color: #00BD85;
  margin: 4px 4px;
}

.side-note-word-span {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height */

  letter-spacing: 0.02em;
  text-transform: capitalize;
}

.side-note-plus-span {
  width: 1.4em;
  height: 1.4em;
}

.add-role-table-header {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 22px; */
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #5E5E5E;
}

.add-role-table-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 22px; */
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.check-functionality .form-check-input {
  background: #E5E5E5;
  border-radius: 20px;
  border-color: #E5E5E5;
  width: 1.2em !important;
  height: 1.2em !important;
}

.check-functionality .form-check-input:checked {
  border-color: #00BD85;
  background-color: #00BD85;
}

.role-done-btn {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  width: 6em;
  height: 2.3em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.role-close-btn {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  width: 6em;
  height: 2.3em;
  background: #FF0000;
  border-color: #FF0000;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.Ap td div {
  cursor: pointer;
}

.select-error-msg {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 0.02em;
  color: #ff0000;
}

.search-select .css-1s2u09g-control {
  width: 100%;
  height: 100% !important;
  align-items: center;
  background: #ffffff !important;
  border-color: rgb(0, 0, 0) !important;
  border-radius: 50px !important;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em !important;
  font-family: 'Almarai', sans-serif;
  justify-content: space-between;
  min-height: 1vw;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  outline: 0px !important;
}

.css-8mmkcg {
  fill: black !important;
}

.css-tj5bde-Svg {
  display: none !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-1okebmr-indicatorSeparator {
  width: 0px !important;
  align-self: none !important;
}

@media only screen and (max-width: 1766px) {
  .dashboard-font {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.02em;
    color: #000000;
  }

  /* .modal-header .btn-close {
    border: 2px solid black !important;
    border-radius: 50% !important;
    padding: 0.8em;
    width: 0.4em !important;
    height: 0.5em !important;
    font-size: 0.5em !important;
    opacity: 0.8 !important;
    border-style: none;
    outline-style: none;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  } */

  .input-wrapper:before {
    content: "";
    position: absolute;
    left: 20px !important;
    top: 1% !important;
    bottom: 0;
    text-align: center;
    width: 16px !important;

    background: url(/static/media/searchRole.ef599069.svg) center / contain no-repeat;
  }

  .input-wrapper input {
    width: 100%;
    height: 3.5em;
    padding-left: 3em;
    padding-right: 3em;
    font-family: 'Almarai', sans-serif;
    outline: none;
    border: 1px solid #5E5E5E;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: 11px;
  }

  .role-search-btn {
    /* position: absolute; */
    width: 7em;
    height: 3em;
    /* margin-left:33vw;
    margin-top: 10%; */
    color: white;
    font-family: 'Almarai', sans-serif;
    background: #000000;
    border-radius: 50px;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    border-style: none;
    outline-style: none;
  }

  .city-select-btn {
    /* position: absolute; */
    width: 10em;
    height: 2.5em;
    /* margin-left:33vw;
      margin-top: 10%; */
    color: #00BD85;
    font-family: 'Almarai', sans-serif;
    background: #ffffff;
    border-radius: 50px;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    text-align: center;
    border: 1px solid #00BD85;
    outline-style: 1px solid #00BD85;
  }

  .add-role-btn {
    width: 11em;
    height: 3em;
    /* margin-top: 10%;
      margin-left: 80%; */
    background: #00BD85;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 12px;
    text-transform: capitalize;
    text-align: center;
    color: #FFFFFF;
    border-style: none;
    outline-style: none;
  }

  .add-role-btn p {
    margin-top: 0.9em;
    margin-left: 0.5em;
  }

  .role-table-header th {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }

  .role-table-text td {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    /* line-height: 2em; */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #5E5E5E;
    vertical-align: middle;
  }

  .role-edit-btn {
    width: 33%;
    height: 22%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
  }

  /* .staff-edit-btn{
      width: 43%;
      height: 22%;
      font-family: 'Almarai', sans-serif;
      color: #FFFFFF;
      background: #000000;
      border-radius: 25px;
      border-color: #000000;
      border-style: none;
      outline-style: none;
    } */
  .staff-edit-btn {
    width: 50%;
    height: 22%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
  }

  .reset-password-btn-staff {
    width: 60%;
    height: 22%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #4eddf0;
    border-radius: 25px;
    border-color: #4eddf0;
    border-style: none;
    outline-style: none;
    margin-left: 4px;
  }


  .form-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }

  .password-confirm-msg {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.02em;
    color: #ff0000;
  }

  .role-input {
    background: #ffffff;
    height: 2em;
    font-size: 0.9em;
    border-radius: 50px;
  }

  .form-select.AF-select {
    display: block;
    width: 100%;
    /* padding: .375rem 2.25rem .375rem .75rem; */
    line-height: 1em;
    -moz-padding-start: calc(0.75rem - 3px);
    background: #E5E5E5;
    height: 2em;
    font-size: 0.9em;
    background: url(/static/media/arrow-down-green.fa55c8d9.svg);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 15px 15px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
  }

  .form-select.select-trade {
    display: block;
    width: 100%;
    /* padding: .375rem 2.25rem .375rem .75rem; */
    line-height: 1em;
    -moz-padding-start: calc(0.75rem - 3px);
    background: #E5E5E5;
    height: 2em;
    font-size: 0.9em;
    background: url(/static/media/arrow-down-green.fa55c8d9.svg);
    background-repeat: no-repeat;
    background-position: left .75rem center;
    background-size: 15px 15px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
  }

  .AF-profile {
    position: absolute;
    top: 15em;
    left: 30%;
    right: 30%;
  }

  .notification {
    position: absolute;
    top: 20em;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .border-bottom {
    border-bottom: 3px dotted #5E5E5E;
  }

  .form-select.customize-select {
    display: block;
    width: 100%;
    /* padding: .375rem 2.25rem .375rem .75rem; */
    line-height: 1em;
    -moz-padding-start: calc(0.75rem - 3px);
    background: #E5E5E5;
    height: 2em;
    font-size: 0.9em;
    background: url(/static/media/arrow-down-green.fa55c8d9.svg);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 15px 15px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
  }

  /* .css-1s2u09g-control{
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem!important;
    -moz-padding-start: calc(0.75rem - 3px);
    background: #E5E5E5!important;
    background-repeat: no-repeat!important;
    background-position: right .75rem center!important;
    background-size: 15px 15px!important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50px!important;
    font-family: 'Almarai', sans-serif;
    height: -30em!important;
    font-size: 0.9em!important;  
  }
  .css-tj5bde-Svg{
    display: none!important;
  }
  .css-1okebmr-indicatorSeparator{
    width: none!important;
  }
  .css-1hb7zxy-IndicatorsContainer{
    display: none!important;
  } */


  .add-role-table-header {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #5E5E5E;
  }

  .add-role-table-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }

  .role-done-btn {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 6em;
    height: 2.3em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  .role-close-btn {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 6em;
    height: 2.3em;
    background: #FF0000;
    border-color: #FF0000;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  /* .btn-close {
    border: 2px solid black !important;
    border-radius: 50% !important;
    width: 0.4em !important;
    height: 0.4em !important;
    font-size: 0.5em !important;
    opacity: 0.8 !important;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  } */

  .input-wrapper:before {
    content: "";
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url(/static/media/searchRole.ef599069.svg) center / contain no-repeat;
  }

  .modal-content {
    width: 45em !important;
    border-radius: 39px;
    padding: 1em;
    margin-left: 17%;
    margin-bottom: 6em;
  }

  .modal-content.dashboard-modal {
    width: 45em !important;
    border-radius: 39px;
    padding: 1em;
    margin-left: 17%;
    margin-bottom: 6em;
    margin-top: 10%;
  }

  .select-error-msg {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.02em;
    color: #ff0000;
  }
}

.form-select.AF-select {
  display: block;
  width: 100%;
  padding: .355rem 2.25rem .355rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  /* line-height: 1.5; */
  background: #E5E5E5;
  /* color: #5E5E5E; */
  /* background-color: #fff; */
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 12px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;

}

.form-select.select-trade {
  display: block;
  width: 100%;
  padding: .355rem 2.25rem .355rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  /* line-height: 1.5; */
  background: #E5E5E5;
  /* color: #5E5E5E; */
  /* background-color: #fff; */
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left .75rem center;
  background-size: 12px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
}

.search-select .css-1s2u09g-control {
  width: 100%;
  height: 100% !important;
  align-items: center;
  background: #ffffff !important;
  border-color: rgb(0, 0, 0) !important;
  border-radius: 50px !important;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em !important;
  font-family: 'Almarai', sans-serif;
  justify-content: space-between;
  /* min-height: 1vw; */
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  outline: 0px !important;
}


@media only screen and (max-width: 1275px) {

  /* .input-wrapper {
    position: absolute;
    margin-left:15%;
    margin-top: 20%;
  } */
  .role-search-btn {
    /* position: absolute; */
    width: 7.5em;
    height: 3em;
    /* margin-left:43%;
    margin-top: 20%; */
    color: white;
    font-family: 'Almarai', sans-serif;
    background: #000000;
    border-radius: 50px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    border-style: none;
    outline-style: none;
  }

  .city-select-btn {
    /* position: absolute; */
    width: 7.5em;
    height: 3em;
    /* margin-left:33vw;
      margin-top: 10%; */
    color: #00BD85;
    font-family: 'Almarai', sans-serif;
    background: #ffffff;
    border-radius: 50px;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    text-align: center;
    border: 1px solid #00BD85;
    outline-style: 1px solid #00BD85;
  }

  .add-role-btn {
    width: 11em;
    height: 3em;
    /* margin-top: 20%;
      margin-left: 80%; */
    background: #00BD85;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 14px;
    text-transform: capitalize;
    text-align: center;
    color: #FFFFFF;
    border-style: none;
    outline-style: none;
  }

  .role-edit-btn {
    width: 50%;
    height: 22%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
  }

  /* .staff-edit-btn{
      width: 42%;
      height: 22%;
      font-family: 'Almarai', sans-serif;
      color: #FFFFFF;
      background: #000000;
      border-radius: 25px;
      border-color: #000000;
      border-style: none;
      outline-style: none;
    } */

  .form-select.AF-select {
    display: block;
    width: 100%;
    padding: .355rem 2.25rem .355rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    /* line-height: 1.5; */
    background: #E5E5E5;
    /* color: #5E5E5E; */
    /* background-color: #fff; */
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 12px 12px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
  }

  .form-select.select-trade {
    display: block;
    width: 100%;
    padding: .355rem 2.25rem .355rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    /* line-height: 1.5; */
    background: #E5E5E5;
    /* color: #5E5E5E; */
    /* background-color: #fff; */
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left .75rem center;
    background-size: 12px 12px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
  }

  .form-select.customize-select {
    display: block;
    width: 100%;
    /* padding: .375rem 2.25rem .375rem .75rem; */
    line-height: 1em;
    -moz-padding-start: calc(0.75rem - 3px);
    background: #E5E5E5;
    height: 2em;
    font-size: 0.9em;
    background: url(/static/media/arrow-down-green.fa55c8d9.svg);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 15px 15px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
  }

  .select-error-msg {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.02em;
    color: #ff0000;
  }

  .input-wrapper:before {
    content: "";
    position: absolute;
    left: 20px !important;
    top: 1% !important;
    bottom: 0;
    text-align: center;
    width: 17px !important;

    background: url(/static/media/searchRole.ef599069.svg) center / contain no-repeat;
  }

  .input-wrapper input {
    width: 100%;
    height: 3.5em;
    padding-left: 3em;
    padding-right: 3em;
    font-family: 'Almarai', sans-serif;
    outline: none;
    border: 1px solid #5E5E5E;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: 11px;
  }

  /* .css-1s2u09g-control{
      width: 100%;
      padding: .375rem 2.25rem .375rem .75rem!important;
      -moz-padding-start: calc(0.75rem - 3px);
      background: #E5E5E5!important;
      background-repeat: no-repeat!important;
      background-position: right .75rem center!important;
      background-size: 15px 15px!important;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 50px!important;
      font-family: 'Almarai', sans-serif;
      height: 0.5em!important;
      font-size: 0.9em!important;
      align-content: center!important;
    
    }
    .css-tj5bde-Svg{
      display: none!important;
    }
    .css-1okebmr-indicatorSeparator{
      width: none!important;
    }
    .css-1hb7zxy-IndicatorsContainer{
      display: none!important;
    }     */
  .modal-content {
    width: 45em !important;
    border-radius: 39px;
    padding: 1em;
    margin-left: 17%;
    margin-bottom: 6em;
  }

  .modal-content.dashboard-modal {
    width: 45em !important;
    border-radius: 39px;
    padding: 1em;
    margin-left: 17%;
    margin-bottom: 6em;
    margin-top: 5%;
  }

}
.role-permissions-select .css-1s2u09g-control {
    background-color: #E5E5E5;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Almarai';
}

.role-multiselect-actions {
    background-color: #E5E5E5;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Almarai';
    color: black;
}

.role-permission-list {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    border: 1px solid black;
    border-radius: 5px;
    padding-top: 0.1rem;
    padding-bottom: 0.2rem;
    font-size: 0.8em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.permission-sub-list {
    color: #7c7e83 !important;
    font-weight: 600 !important;
}

.role-add-btn-with-loader-spinner {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 5.7rem;
    height: 1.9rem;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
}

.roleCloseBtn {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 5.7rem;
    height: 1.9rem;
    background: #ff0000;
    border-color: #ff0000;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-right: 0;
}


.img-error-screen{
    width: 18vw;
    margin-top: 12vh;
}
.error-screen-text-msg{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    letter-spacing: 0.02em;
    color: #F4B844;  
    margin-top: 6vh;
}
.back-home-btn{
    margin-top: 6vh;
    width: 80%;
    padding: 4.5%;
    font-family: 'Almarai', sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: #FFFFFF;
    background: #00BD85;
    border-radius: 50px;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
}
  /* 120% l fo2  */
  @media only screen and (max-width: 1597px) {
    .error-screen-text-msg{
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        letter-spacing: 0.02em;
        color: #F4B844;  
        margin-top: 7vh;
    }
    .back-home-btn{
        margin-top: 6vh;
        width: 80%;
        padding: 4%;
        font-family: 'Almarai', sans-serif;
        font-weight: bold;
        font-size: 17px;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 50px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
    }
    
    
  }
/* 150 l fo2 */
@media only screen and (max-width: 1300px) {
    .error-screen-text-msg{
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        letter-spacing: 0.02em;
        color: #F4B844;  
        margin-top: 7vh;
    }
    .back-home-btn{
        margin-top: 6vh;
        width: 80%;
        padding: 4%;
        font-family: 'Almarai', sans-serif;
        font-weight: bold;
        font-size: 17px;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 50px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
    }
    
}   

/* mobile */
@media only screen and (max-width: 1100px) {
    .img-error-screen{
        width: 18vw;
        margin-top: 40vh;
    }
    .error-screen-text-msg{
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        letter-spacing: 0.02em;
        color: #F4B844;  
        margin-top: 4vh;
    }
    .back-home-btn{
        margin-top: 4vh;
        width: 100%;
        padding: 4%;
        font-family: 'Almarai', sans-serif;
        font-size: 11px;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 50px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
    }

}    
.role-edit-btn-cities{
    width: 43%;
    height: 12%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;  
  }    



.agent-msg{
    font-size: 14px!important;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #000000;
}
@media only screen and (max-width: 1275px) {
    .agent-msg{
    font-size: 12px!important;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #000000;
  
}
}
@media only screen and (max-width: 1766px) {
    .agent-msg{
      font-size: 12px!important;
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.02em;
      color: #000000;
    
    }
}  
.collectors-table-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 50%; */
    font-size: 0.9rem;
    height: 22%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
    padding: 0.2rem 0.6rem;
}

.collectors-table-btns-icons {
    font-size: 1rem;
    margin-left: 0.2rem;
}

.retailer-info-in-view-modal {
    font-size: 1.1rem;
    font-family: 'Almarai', sans-serif;
    font-weight: 500;
    color: #000000;
    text-transform: capitalize;
}

.retailers-icons-in-view-modal {
    font-size: 1rem;
    margin-right: 0.5rem;
}

.collector-trash-icon {
    font-size: 1.5rem;
    color: red;
    cursor: pointer;
}

.collector-trash-icon-clicked {
    font-size: 1.5rem;
    color: rgb(196, 192, 192);
    cursor: progress;
}

.collectors-table-profile-details-icons {
    font-size: 1.5em;
}

.collectors-table-profile-details-icons:hover {
    cursor: pointer;
    border-radius: 50%;
    background-color: black;
    color: white;
}
.collector-wallet {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #000000;
}

.collector-page-layout ul li .nav-link.active {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #00BD85;
}

.collector-page-layout ul li .nav-link {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #000000;
}

.collector-page-layout {
    position: absolute;
    left: 13%;
    width: 80%;
    top: 15%;
}

.Collector-details-container {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #000000;
    border-radius: 7px;
    background-color: rgb(246, 244, 247);
    padding: 1rem 1.5rem 1rem 1.5rem;
    line-height: 3rem;
}

.collector-details-icon-container {
    background-color: #e0e2e2;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 0.7rem;
}

.collector-details-icon {
    font-size: 1rem;
    color: #00BD85;
}

.collector-clickable-icon {
    cursor: pointer;
}
.loadingText{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.9rem;
    letter-spacing: 0.02em;  
    color: rgb(2, 0, 0);
}
.double-action-btns-table{
    margin: 1%;
    padding: 1%;
    width: 20%;
    height: 1.7vw;
    font-family: 'Almarai', sans-serif;
    font-weight:500;
    letter-spacing: 0.02em;
    font-size: 0.9vw;
    color: #FFFFFF;
    /* background: #000000; */
    border-radius: 25px;
    /* border-color: #000000; */
    border-style: none;
    outline-style: none;
}
.labels-box{
    padding-top: 2%;
    padding-bottom: 5%;
    text-align: center;
    border: 1px solid #000000;
    border-radius: 15px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.8vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}
.labels-box .col-3{
    background-color: #cecbcb;
    border-radius: 20px;
    padding: 0.8%;
    margin-left: 1%;
}
.labels-box span{
    width: 80%;
}
.labels-box .cancel-label{
    width: 20%;
    cursor: pointer;
}
.labels-box .cancel-label-clicked{
    width: 20%;
    cursor:wait;
}
.add-label-btn{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 5.7rem;
    height: 1.9rem;
    background: rgb(20, 176, 238);
    border-color: rgb(20, 176, 238);
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-right: 0;
  }
  
.clickable-row:hover td {
    cursor: pointer;
    background-color: rgba(182, 182, 182, 0.2);
    font-weight: bold;
    color: #00bd85;
}

.layout-invoices {
    position: absolute;
    width: 83%;
    margin-left: 15%;
    margin-top: 6%;
}

.switch-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    margin-right: 0.5em;
}

.text-label {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.invoices-input {
    background: #E5E5E5;
    width: 70%;
    height: 2.3vw;
    font-size: 0.9vw;
    border-radius: 50px;
}

.profile-input {
    background: #E5E5E5;
    width: 80%;
    height: 2.3vw;
    font-size: 0.9vw;
    border-radius: 50px;
}

.invoices-icon {
    margin-left: 0.3em;
    margin-top: -5px;
    cursor: pointer;
    font-size: 1.3vw;
}

.invoice-img {
    width: 13vw;
    height: 13vw;
}

.add-note-btn {
    width: 7.3vw;
    padding: 0.3vw;
    /* height: 22%; */
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
}

.add-note-icon {
    margin-right: 0.3em;
    font-size: 0.9vw
}

.add-note-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.1vw !important;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.add-note-form-label {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.load_more_text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #ABABAB;
    margin-right: 0.4em;

}

.add-note-form-textarea {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    border-radius: 7px;
    background-color: rgb(246, 244, 247);
}

.submit-addNote-btn {
    width: 48%;
    padding: 0.5vw;
    height: 2.4vw;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #00BD85;
    border-radius: 8px;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
}

.cancel-addNote-btn {
    width: 48%;
    padding: 0.5vw;
    height: 2.4vw;
    /* margin-left: 0.5em; */
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #ff0000;
    background: #ffffff;
    border-radius: 8px;
    border-color: #ff0000;
    border: 1px solid #ff0000;
    outline-style: none;
}

.cancel-addNote-btn:hover {
    width: 48%;
    padding: 0.5vw;
    height: 2.4vw;
    /* margin-left: 0.5em; */
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #ffffff;
    background: #ff0000;
    border-radius: 8px;
    border-color: #ff0000;
    border-style: none;
    outline-style: none;
}

.addNote-footer.modal-footer {
    padding-left: 0;
    padding-right: 0;
}

.note-container {
    background-color: #E5E5E5;
    border-radius: 50px;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    margin-top: 1.3vw;
}

.note-title-wrapper {
    margin-left: 1vw;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #5E5E5E;
    background-color: #ffffff;
    border-radius: 50px;
    padding-left: 2.3vw;
    padding-right: 2.3vw;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
}

.note-main-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #5E5E5E;
}

.profile-img-setting-icon {
    font-style: normal;
    font-weight: 700;
    font-size: 5vw;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    border: 1px solid black;
    border-radius: 50%;
    padding: 1.4vw;
    cursor: pointer;
}

.notification-header {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    letter-spacing: 0.02em;
    color: #000000;
}

.notification-msg-body {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.87rem;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 0.5rem 0.8rem;
    height: 5.7rem;
}

.notification-msg-title::placeholder,
.notification-msg-body::placeholder {
    color: #A6A6A6;
}

.notification-msg-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.87rem;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 0.9rem 0.8rem;
    height: 3.1rem;
}

.switch-notification-options-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    color: #000000;
}

.switch-notification-options-text:hover,
.switch-notification-options-text-active:hover {
    cursor: pointer;
}

.switch-notification-options-text-active {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    color: #00BD85;
    padding-bottom: 0.3rem;
    border-bottom: 2px solid #00BD85;
}

.notification-status-title,
.notification-status-checkbox,
.notification-industry-title,
.notification-city-title,
.notification-search-by-number-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.87rem;
    color: #000000;
}

.notification-status-checkbox .form-check {
    display: flex;
    align-items: center;
}

.notification-status-checkbox .form-check input {
    width: 1.25;
    height: 1.25;
    border-color: #000;
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
}

.notification-status-checkbox .form-check input:checked {
    background-color: #00BD85;
    border-color: #00BD85;
}

.notification-city-filter .css-1s2u09g-control,
.notification-industry-filter .css-1s2u09g-control,
.notification-city-filter .css-1pahdxg-control,
.notification-industry-filter .css-1pahdxg-control {
    border: 1px solid #707070;
    border-radius: 10px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.87rem;
    height: 3.1rem;
}
.notification-search-by-number-input {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.87rem;
    height: 3.1rem;
    border: 1px solid #707070;
    border-radius: 10px;
}

.notification-search-by-number-input::placeholder {
    color: #A6A6A6;
}

.notification-disabled-search-input {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.87rem;
    height: 3.1rem;
    border-radius: 10px;
    background-color: #eeeeee;
}

.notification-search-by-number-loader {
    background-color: #eeeeee;
    color: #A6A6A6;
}

.notification-search-by-number-loader .spinner-border {
    width: 1rem;
    height: 1rem;
    font-size: 0.5rem;
}

.notification-result-names {
    border: 1px solid #707070;
    border-radius: 10px;
    height: 7.6rem;
    overflow-y: scroll;
    padding: 1rem;
}

.notification-searched-name {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    border: 1px solid #707070;
    border-radius: 10px;
    color: #A6A6A6;
    min-height: 2.1rem;
    max-width: auto;
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
    overflow: hidden;
    word-wrap: break-word;
}
.notification-search-by-number-error-msg{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.87rem;
    color: red;
}
.notification-delete-name-icon {
    font-size: 0.9rem;
}

.notification-delete-name-icon:hover {
    color: #000;
    cursor: pointer;
}

.notification-footer-btn {
    width: 100%;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    background: #00BD85 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 1rem;
    border-style: none;
    outline-style: none;
    height: 2.5rem;
}

.multi-select-label-accounts.multiSelectContainer {
    background: #E5E5E5;
    width: 80%;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.multi-select-filters-accounts.multiSelectContainer {
    background: #ffffff;
    width: 100%;
    z-index: 999;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.8rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    /* border: 1px solid #5E5E5E; */
    border-style: none;
    outline-style: none;
}

.multi-select-filters-accounts.multiSelectContainer ul {
    /* max-height: 11vh; */
}

.accounts-date-filter {
    width: 100%;
    min-height: 2.3rem;
    margin-top: 0.4rem;
    z-index: 999;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.8rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #7c7c7c;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.industrySelectbox .css-1s2u09g-control,
.industrySelectbox .css-b62m3t-container,
.industrySelectbox .css-1pahdxg-control,
.industrySelectbox .css-lgtuOrj-indicatorContainer,
.citySelectbox .css-1s2u09g-control,
.citySelectbox .css-b62m3t-container,
.citySelectbox .css-1pahdxg-control,
.citySelectbox .css-lgtuOrj-indicatorContainer {
    background: #E5E5E5;
    border: 1px solid #ccc;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    min-height: 2.2rem;
}

.profile-label-text {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.sms-input-field {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9em;
    /* line-height: 0.02em; */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    background-color: #ffffff !important;
    border-radius: 50px;
    border: 1px solid #000000;
    padding: 0.5rem;
}

.account-profile-titles {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 600;
    /* font-size: 1.5rem; */
    line-height: 0.02em;
    letter-spacing: 0.02em;
    /* text-transform: capitalize; */
    color: #00BD85
}

.profile-store-payments-btn-selected {
    background: #00BD85;
    height: 3rem;
    width: 100%;
    border-radius: 40px;
    border: none;
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    text-transform: capitalize;
    color: #FFFFFF;
}

.profile-store-payments-btn-unselected {
    background: 0;
    height: 3rem;
    width: 100%;
    border-radius: 40px;
    border: none;
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    text-transform: capitalize;
    color: #5E5E5E;
}

.create-invoice-btn {
    background: #64024c;
    height: 3rem;
    width: 40%;
    border-radius: 40px;
    border: 0;
    outline: none;
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    text-transform: capitalize;
    color: #FFFFFF;

}

.ws-name-in-invoice-modal {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #07506d;
    margin-left: 0.5rem;
    /* border-bottom: 2px solid #0a96cd;
    padding-bottom: 0.3rem;
    display: inline-block; */
}

.ws-dates-in-invoice-modal {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #07506d;
    text-align: center;
}

.add-new-item-in-invoice {
    vertical-align: middle;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.95em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #00BD85;
    cursor: pointer;
}

.invoice-recycle-bin {
    width: 40%;
    height: 100%;
    color: #eeb5b5;
    cursor: pointer;
    /* margin-top: 0.8rem; */
}

.invoice-recycle-bin:hover {
    width: 40%;
    height: 100%;
    color: #ff0000;
    cursor: pointer;
    /* margin-top: 0.8rem; */
}

.borderBottom {
    border-bottom: 2px solid #000;
    display: inline-block;
    min-width: 9rem;
}

.transaction-back-btn {
    width: 48%;
    padding: 0.5vw;
    height: 2.4vw;
    /* margin-left: 0.5em; */
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #ffffff;
    background: #000000;
    border-radius: 8px;
    /* border-color: #000000; */
    border: 1px solid #000000;
    outline-style: none;
}

.transaction-border-bottom-dates {
    border: 2px solid #646046;
    padding: 2px 5px 2px 5px;
}

.ws-social-links {
    width: 100%;
}

.ws-sales-number {
    width: 20rem;
}

.ws-social-links-container {
    /* background: #e9ecef;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 10px 10px 5px #c1c3c5; */

    background: #e9ecef;
    width: 15rem;
    height: 11rem;
    border-radius: 13%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 10px 10px 5px #c1c3c5;

}

.ws-sales-number-container:hover,
.ws-social-links-container:hover {
    opacity: 0.8;
    cursor: pointer;
}

.salesNumbersInputs {
    background: #E5E5E5;
    width: 90%;
    height: 2.3vw;
    font-size: 12px;
    font-weight: 400;
    border-radius: 10px;
}

.ws-profile-telegram-icon {
    color: #0088cc;
    font-size: 1.5rem;
}

.ws-profile-whatsapp-icon {
    color: #0ac40acf;
    font-size: 1.5rem;
}

.ws-profile-instagram-icon {
    position: relative;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 20%;
    background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)
}

.ws-profile-instagram-icon:after,
.ws-profile-instagram-icon:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.9rem;
    height: 0.9rem;
    border: 2px solid #fff;
    transform: translate(-50%, -50%);
    content: ''
}

.ws-profile-instagram-icon:before {
    border-radius: 20%
}

.ws-profile-instagram-icon:after {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%
}

.ws-profile-facebook-icon {
    color: #3b5998;
    font-size: 1.5rem;
}

.ws-profile-salesnumber-call-icon {
    color: #040c1d;
    font-size: 1.5rem;
}

.create-pos-modal-title,
.change-pos-password-modal-title {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 800;
    font-size: 1.1em;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.recyle-bin-icon-in-profile-confirmation-modal {
    color: red;
    font-size: 3rem;
    margin-bottom: 1rem;
}

.catchErrorMsg {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.9rem;
    letter-spacing: 0.02em;
    color: rgb(255, 0, 0);
}

.sync-btn-progress-behavior,
.sync-btn-pointer-behavior,
.download-btn-pointer-behavior,
.download-btn-progress-behavior {
    font-size: 2.4rem;
    margin-left: 0.5rem;
}

.sync-btn-pointer-behavior:hover,
.download-btn-pointer-behavior:hover {
    cursor: pointer;
}

.sync-btn-progress-behavior:hover,
.download-btn-progress-behavior:hover {
    cursor: progress;
}
.notFountText{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.9rem;
    letter-spacing: 0.02em;  
    color: red;
}
.dashboard-logs-btn {
    background-color: #5617e9;
    color: rgb(255, 255, 255);
    border: 0px solid #5617e9;
}

.dashboard-logs-Btn:hover {
    opacity: 1;
}
.dashboard-logs-offcanvas{
    margin-left: 12%;
    background-color: #ededed;
    font-family: 'Almarai';
    font-style: normal;
}
.clickable-row:hover td {
    cursor: pointer;
    background-color: rgba(182, 182, 182, 0.2);
    font-weight: bold;
    color: #00bd85;
}

.profile-img-setting-icon {
    font-style: normal;
    font-weight: 700;
    font-size: 5vw;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    border: 1px solid black;
    border-radius: 50%;
    padding: 1.4vw;
    cursor: pointer;
}

.multi-select-label-accounts.multiSelectContainer {
    background: #E5E5E5;
    width: 80%;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.multi-select-filters-accounts.multiSelectContainer {
    background: #ffffff;
    width: 100%;
    z-index: 999;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.8rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    /* border: 1px solid #5E5E5E; */
    border-style: none;
    outline-style: none;
}

.multi-select-filters-accounts.multiSelectContainer ul {
    /* max-height: 11vh; */
}

.accounts-date-filter {
    width: 100%;
    min-height: 2.3rem;
    margin-top: 0.4rem;
    z-index: 999;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.8rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #7c7c7c;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.profile-label-text {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.sms-msg-body {
    background-color: #e6e5f3;
    color: rgb(0, 0, 0);
    min-height: 13.5em;
    border-radius: 25px;
    padding: 1vw;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9vw;
    word-spacing: 0.3rem;
    line-height: 1.5rem;
}

/* li.msg-content{
    word-spacing: 0.3rem;
    line-height: 1.5rem;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9vw;
} */
.page-link:focus {
    box-shadow: 0 0 0 0 rgb(13 110 253 / 25%);
}

.pagination {
    display: flex;
    justify-content: center;
}

.retailer-table-pagination .page-link,
.wholesaler-table-pagination .page-link {
    position: relative;
    display: block;
    color: #0d6efd;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-radius: 20px;
    width: 1rem;
    height: 1.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    align-items: center;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.retailer-table-pagination .page-link:hover,
.wholesaler-table-pagination .page-link:hover {
    color: #ffffff;
    background-color: #0d6efd;
}

.sync-btn-progress-behavior,
.sync-btn-pointer-behavior,
.download-btn-pointer-behavior,
.download-btn-progress-behavior {
    font-size: 2.4rem;
    margin-left: 0.5rem;
}

.sync-btn-pointer-behavior:hover,
.download-btn-pointer-behavior:hover {
    cursor: pointer;
}

.sync-btn-progress-behavior:hover,
.download-btn-progress-behavior:hover {
    cursor: progress;
}
.tableCard {
  width: 10rem;
  line-height: 0.8em;
  line-height: 1em;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.2);
  /* this adds the "card" effect */
  border-radius: 30px;
}

.cardTitle {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.cardText {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 0.5em;
  margin-top: 1em;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
}

.pending-btn {
  /* width: 33%; */
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #00BD85;
  border-color: #00BD85;
  border-style: none;
  outline-style: none;
  font-size: 15px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.modal-header-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.modal-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.7em;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.confirm-msg-modal-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.AP-modal-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.7em;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
}

.AP-modal-user-type-radio {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.basic-info-input {
  position: absolute;
  width: 500px;
  height: 70px;
  left: 741px;
  top: 816px;

  background: #E5E5E5;
  border-radius: 50px;
}

.profile-td {
  width: 200px;
}

.discount-btn-1 {
  background: #7952B3;
  border: 1px solid #7952B3;
  box-sizing: border-box;
  border-radius: 50px;
  /* width: 220px;
height: 40px; */
}

.action-btns-in-profiles {
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8vw;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  outline: none;
  min-width: 60%;
  /* min-height: 50%; */
}

.retailer-profile-logs, .ws-profile-logs {
  height: 2.5rem;
  width: 100%;
  border-radius: 10px;
  border: none;
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  text-transform: capitalize;
  opacity: 0.9;
}

.change-password-btn {

  background: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}

.change-password-btn:hover {

  background: #000000;
  border: 1px solid #000000;
  color: #fff;
}

.delete-btn-in-profile {
  background: #ffffff;
  border: 1px solid #ff0000;
  color: #ff0000;
}

.delete-btn-in-profile:hover {
  background: #ff0000;
  border: 1px solid #ff0000;
  color: #fff;
}

.save-basic-info {
  background: #00BD85;
  border: 1px solid #00BD85;
  color: #ffffff;
}

.save-basic-info:hover {
  background: #00BD85;
  border: 1px solid #00BD85;
  color: #ffffff;
}

.PIN {
  background: #fff;
  border: 1px solid #56bbff;
  border-radius: 50px;
  color: #56bbff;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8vw;
  width: 60%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  outline: none;
}

.PIN:hover {
  background: #56bbff;
  border: 1px solid #56bbff;
  border-radius: 50px;
  color: #fff;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8vw;
  width: 60%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  outline: none;
}

.pin-err-msg {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-size: 0.8rem;
  font-weight: 700;
  color: red;
}

.generate-otp-in-profile {
  background: #ffffff;
  border: 1px solid #ab13ca;
  color: #ab13ca;
}

.generate-otp-in-profile:hover {
  background: #ab13ca;
  border: 1px solid #ab13ca;
  color: #fff;
}

.otp-icon {
  margin-right: 0.3rem;
  font-size: 1.1rem;
  color: #000000;
}

.otp-value {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  background: #E5E5E5;
  border-radius: 50px;
  padding: 0.2rem 2.5rem 0.2rem 2.5rem;
}

.otp-value-error-msg {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ff0000;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  text-align: center;
}

.retailerTrippleToggleSwitchText,
.wsToggleSwitchText {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 0.8vw;
  text-align: center;
  margin-right: 0.3rem;
  /* min-width: 50%;  
  max-width: 50%; */
}

.retailerTrippleToggleSwitch .css-1dfzgwp .css-aj9s48 {
  background-color: red;
}

.retailerTrippleToggleSwitch .css-1dfzgwp .css-65zkr6 {
  background-color: #e3f305;
}

.retailerTrippleToggleSwitch .css-1dfzgwp .css-oihcde {
  background-color: #00BD85;
}

.notification-btn, .sms-btn {
  background-color: #01bd85;
  color: #ffffff;
  border: 0px solid #00BD85;
}
.accounts-table-action-btns{
  box-sizing: border-box;
  border-radius: 50px;
  padding: 0.5rem 0.9rem;
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.apply-filter {

  box-sizing: border-box;
  border-radius: 50px;
  width: 120px;
  height: 40px;
  background: #00BD85;
  border: 1px solid #00BD85;
}

.cancel-basic-info {
  background: #fff;
  border: 1px solid #ff0000;
  border-radius: 50px;
  color: #ff0000;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8vw;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  outline: none;
}

.cancel-basic-info:hover {
  background: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 50px;
  color: #ffffff;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8vw;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  outline: none;
}

.cancel-visit {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #ff0000;
  border-color: #ff0000;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.action-visit {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.save-edits {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #0071bd;
  border-color: #0071bd;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.reviewed-btn-ws-activation-form {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 11em;
  height: 2.7em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 0.5rem;
}

.Assignment-accepted {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 11em;
  height: 2.7em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.revert-visit {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #F4B844;
  border-color: #F4B844;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.revert-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.revertImg {
  width: 12em;
}

.revertModal .modal-content {
  width: 50em !important;
  border-radius: 39px;
  padding: 1em;
  margin-bottom: 25%;
}


.role-Ap-input.form-control[readonly] {
  background: #ffffff;
  border-color: #000000;
  border-radius: 50px;
}

.Ap-logs {
  border-left: 2px solid #00BD85;
}

.logs-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.logs-data {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
  margin-right: 1em;
}

.Ap-logs .info {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
  margin-top: -1em;
}

.retailer-Ap-text-area {
  border-radius: 20px;
  padding: 25px;
  margin-bottom: 6vh;
  background-color: #FFFFFF !important;
}

.success-modal-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;

}

.server-msg-modal .modal-content {
  min-height: 15em;
}

/* .server-msg-modal .modal-header .btn-close {
  padding: 0.7em;
} */

.Ap-table-layout {
  position: absolute;
  left: 13%;
  width: 120em !important;
  top: 37%;
  overflow: auto;

}

.Ap-table-layout td,
.Ap-table-layout tbody,
.Ap-table-layout td,
.Ap-table-layout tfoot,
.Ap-table-layout th,
.Ap-table-layout thead,
.Ap-table-layout tr {
  border-style: none !important;
}

.scroll-table {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
  z-index: 99
}

.scroll-table thead th {
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: white;
  bottom: 3em;
  padding-bottom: 2em;
}

.retailer-Ap-img {
  height: 14.5vh
}

.reschedule-modal .modal-content {
  width: 35em !important;
}

.img-icon {
  /* margin-top: 0.7em; */
}

.img-name {
  margin-top: 0.8vh;
  margin-left: 0.8em;
}

.download-icon {
  margin-top: 0.3em;
  cursor: pointer;
}

.loader {
  margin-right: 0.3rem;
  margin-top: 0.3rem;
  width: 1rem;
  height: 1rem;
  cursor: wait;
}

.download-contract {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  width: 10em;
  height: 2.2em;
  background: #002fff;
  border-color: #002fff;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  /* margin-left: 100%; */
}

.Ap-register-image {
  height: 100%;
  height: 100%;
  /* height:4em */
}

.cancelation-label-form {
  font-size: calc(.1rem + 1vw);
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: #000000;
}

/* 120% l7d zoom in 150% */
@media only screen and (max-width: 1597px) {
  .tableCard {
    width: 8.5rem !important;
    /* height: 9.5rem!important; */
    line-height: 0.8em !important;
    border-radius: 30px !important;
  }

  .scroll-table {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
    z-index: 99;
    /* padding-right: 11em!important; */
  }

  .cardTitle {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    margin-right: 5em !important;
  }

  .role-Ap-input {
    height: 2em;
    font-size: 0.9em;
    background: #ffffff;
    border-color: #000000;
    border-radius: 50px;
  }

  .pending-btn {
    /* width: 33%; */
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #00BD85;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
    font-size: 11px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .cancel-visit {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #ff0000;
    border-color: #ff0000;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .action-visit {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  .save-edits {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #0071bd;
    border-color: #0071bd;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  .reviewed-btn-ws-activation-form {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 10em;
    height: 2.7em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 0.5rem;
  }

  .Assignment-accepted {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 10em;
    height: 2.7em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  .revert-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }

  .revertImg {
    width: 12em;
  }

  .retailer-Ap-text-area {
    border-radius: 20px;
    padding: 3px;
    margin-bottom: 9.2vh;
    background-color: #FFFFFF !important;
  }

  .retailer-Ap-img {
    height: 13.5vh;
  }

  .Ap-table-layout {
    position: absolute;
    left: 13%;
    width: 115em !important;
    top: 37%;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  .reschedule-modal .modal-content {
    width: 30em !important;
  }

  .revertModal .modal-content {
    width: 25em !important;
    border-radius: 39px;
    padding: 1em;
    margin-left: 30%;
    margin-bottom: 35%;
  }

  .img-icon {
    margin-top: 0.7em;
  }

  .img-name {
    margin-top: 3vh;
    margin-left: 0.8em;
  }

  .download-icon {
    margin-top: 0.9em;
    cursor: pointer;
  }

  .download-contract {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    width: 17em;
    height: 2.2em;
    background: #002fff;
    border-color: #002fff;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .Ap-register-image {
    width: 100%;
    height: 100%;
  }
}

/* 110% l7d zoom out ab3d */
@media only screen and (min-width: 1598px) {
  .scroll-table {
    overflow-y: scroll;
    overflow-x: hidden !important;
    height: 500px;
    z-index: 99;
    /* padding-right: 13em!important; */
  }

  .Ap-table-layout {
    position: absolute;
    left: 13%;
    width: 127em !important;
    top: 37%;
    overflow-x: hidden !important;
    overflow-y: hidden !important;

  }

  /* .firstTeam-btn{
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #84e4f5;
    border-color: #84e4f5;
    border-style: none;
    outline-style: none;
    font-size: 10px!important;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  } */

}

.activation-process-loading-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.02em;
  color: #000000;
}


.AP-user-type-select-box .css-1s2u09g-control,
.AP-user-type-select-box .css-b62m3t-container,
.AP-user-type-select-box .css-1pahdxg-control,
.AP-user-type-select-box .css-lgtuOrj-indicatorContainer {
  background: #e5e5e5de;
  border-color: #e5e5e5de;
  /* height: 3em; */
  font-size: 0.8rem;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  border-radius: 7px;

}

.assign-user-type {
  cursor: progress;
}
.AP-skeleton-ui-actions-btn {
    height: 22%;
    padding: 6px;
    width: 70%;
    border-style: none;
    outline-style: none;
    font-size: 11px;
    letter-spacing: 0.02em;
    border-radius: 7px;
}
.link-to-profile-icon-through-af {
  margin-left: 0.3em;
  margin-top: -17px;
  cursor: pointer;
  font-size: 1.2vw;
}

.Ap-table tbody,
.Ap-table td,
.Ap-table tfoot,
.Ap-table th,
.Ap-table thead,
.Ap-table tr {
  border-color: 0 !important;
  border-style: none !important;
  border-width: 0 !important;
}

.tableCard {
  width: 10rem !important;
  line-height: 0.8em !important;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.2) !important;
  /* this adds the "card" effect */
  border-radius: 30px !important;
  /* height: 11.5rem!important; */
}

.cardTitle {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;

}

.cardText {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 1.7em;
  margin-top: 1em;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
}

.pending-btn {
  /* width: 33%; */
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #00BD85;
  border-color: #00BD85;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.firstVisit-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #dfee58;
  border-color: #dfee58;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.firstTeam-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #84e4f5;
  border-color: #84e4f5;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.secondTeam-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #11b2ce;
  border-color: #11b2ce;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.contract-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #00BD85;
  border-color: #00BD85;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.posVisit-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #dfee58;
  border-color: #dfee58;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.final-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #08d112;
  border-color: #08d112;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.secondVisit-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #dfee58;
  border-color: #dfee58;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.secondVisitFirstTeam-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #ff7741;
  border-color: #ff7741;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.secondVisitSecondTeam-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #ec4c1b;
  border-color: #ff7741;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.modal-header-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.modal-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.7em;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.AP-modal-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.7em;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
}
.cancel-visit {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #ff0000;
  border-color: #ff0000;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.cancel-visit-team2 {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 9em;
  height: 2.7em;
  background: #ff0000;
  border-color: #ff0000;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.action-visit {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.save-edits {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #0071bd;
  border-color: #0071bd;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.review-visit-team2 {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 10em;
  height: 2.7em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.discount-btn {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 10em;
  height: 2.7em;
  background: #7952B3;
  border-color: #7952B3;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.book-pos-btn {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #000000;
  border-color: #000000;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.revert-visit {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #F4B844;
  border-color: #F4B844;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.revert-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.revertImg {
  width: 12em;
}

.revertModal .modal-content {
  margin-bottom: 33%;
}

.role-Ap-input.form-control[readonly] {
  background: #ffffff;
  border-color: #000000;
  border-radius: 50px;
}

.Ap-logs {
  border-left: 2px solid #00BD85;
}

.logs-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.logs-data {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
  margin-right: 1em;
}

.Ap-logs .info {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
  margin-top: -1em;
}

.Ap-text-area {
  border-radius: 20px;
  padding: 19px;
  background-color: #FFFFFF !important;
}

.Ap-register-image {
  width: 100%;
  height: 100%;
  /* height:4em */
}

.success-modal-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;

}

.server-msg-modal .modal-content {
  min-height: 15em;
}

/* .server-msg-modal .modal-header .btn-close {
  padding: 0.7em;
} */

.Ap-table-layout {
  position: absolute;
  left: 13%;
  width: 100%;
  top: 37%;
  overflow: auto;
}

.Ap-table-layout td,
.Ap-table-layout tbody,
.Ap-table-layout td,
.Ap-table-layout tfoot,
.Ap-table-layout th,
.Ap-table-layout thead,
.Ap-table-layout tr {
  border-style: none !important;
}

.scroll-table {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
  z-index: 99
}

.scroll-table thead th {
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: white;
  bottom: 3em;
  padding-bottom: 2em;
}

.input-img-upload {
  /* background: url("../../icons/uploadImg.png") center / contain no-repeat;
  background-size: 100%;
  width: 100%;
  height: 8em;
  visibility: hidden; */

  /* background-size: 3em */
}

.discount {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 22px; */
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #00BD85;
}

.discount-input {
  background: #E5E5E5;
  border-radius: 50px;
  font-size: 13px;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 19px;
  padding-right: 19px;
}

.amount-input {
  background: #E5E5E5;
  border-radius: 50px;
  font-size: 13px;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 19px;
  padding-right: 19px;
  width: 80px;
}

.discountModal .modal-content {
  width: 50em !important;
  border-radius: 39px;
  padding: 1em;
  margin-bottom: 25%;
}

.submitContract {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  text-align: center;
  margin-top: 1.5em;
  padding-top: 0.5em;
}

.thead-profile {
  border-bottom: 0;
}

.profile-active {
  color: rgba(0, 189, 133, 1);
}

.profile-pending {
  color: rgba(244, 184, 68, 1);
}

.profile-inActive {
  color: rgb(255, 0, 0);
}

.download-contract {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  width: 13em;
  height: 2.2em;
  background: #002fff;
  border-color: #002fff;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  /* margin-left: 100%; */
}

.cancelation-label-form {
  font-size: calc(.1rem + 1vw);
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: #000000;
}

.role-done-btn-loader {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  width: 7em;
  height: 2.3em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.cancellation-modal-done-btn-loader,
.cancellation-modal-done-btn {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;
  width: 5.5rem;
  height: 2.1rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.cancellation-modal-cancel-btn-loader {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;
  width: 5.5rem;
  height: 2.1rem;
  background: #FF0000;
  border-color: #FF0000;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

@media only screen and (max-width: 1600px) {
  .tableCard {
    width: 8.5rem !important;
    /* height: 9.5rem!important; */
    line-height: 0.8em !important;
    border-radius: 30px !important;
  }

  .cardTitle {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }

  .cardText {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 1.5em;
    margin-top: 1em;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #ABABAB;
  }

  .pending-btn {
    /* width: 33%; */
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #00BD85;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .firstVisit-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #dfee58;
    border-color: #dfee58;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .firstTeam-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #84e4f5;
    border-color: #84e4f5;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .secondTeam-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #11b2ce;
    border-color: #11b2ce;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .contract-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #00BD85;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .posVisit-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #dfee58;
    border-color: #dfee58;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .final-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #08d112;
    border-color: #08d112;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .secondVisit-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #dfee58;
    border-color: #dfee58;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .secondVisitFirstTeam-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #ff7741;
    border-color: #ff7741;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .secondVisitSecondTeam-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #ec4c1b;
    border-color: #ff7741;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .role-Ap-input {
    height: 2em;
    font-size: 0.9em;
    background: #ffffff;
    border-color: #000000;
    border-radius: 50px;
  }

  .cancel-visit {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #ff0000;
    border-color: #ff0000;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .submitContract {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    text-align: center;
    margin-top: 1.5em;
    padding-top: 0.5em;
  }

  .cancel-visit-team2 {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8.5em;
    height: 2.7em;
    background: #ff0000;
    border-color: #ff0000;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .action-visit {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .save-edits {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #0071bd;
    border-color: #0071bd;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  .review-visit-team2 {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 9em;
    height: 2.7em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .discount-btn {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 9em;
    height: 2.7em;
    background: #7952B3;
    border-color: #7952B3;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .book-pos-btn {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #000000;
    border-color: #000000;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .revert-visit {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #F4B844;
    border-color: #F4B844;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  .revertImg {
    width: 12em;
  }

  .Ap-register-image {
    width: 100%;
    height: 100%;

    /* height:4em */

  }

  .Ap-text-area {
    border-radius: 20px;
    padding: 1px;
    background-color: #FFFFFF !important;
  }

  .Ap-table-layout {
    position: absolute;
    left: 13%;
    width: 90em;
    top: 37%;
    /* overflow: auto; */
  }

  .revertModal .modal-content {
    width: 25em !important;
    border-radius: 39px;
    padding: 1em;
    margin-left: 30%;
    margin-bottom: 35%;
  }

  .discount {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #00BD85;

  }

  .discount-input {
    background: #E5E5E5;
    border-radius: 50px;
    font-size: 10px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .discountModal .modal-content {
    width: 50em !important;
    border-radius: 39px;
    padding: 1em;
    margin-bottom: 25%;
  }

  .amount-input {
    background: #E5E5E5;
    border-radius: 50px;
    font-size: 13px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 19px;
    padding-right: 19px;
    width: 80px;
    height: 30px;
  }

  .download-contract {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    width: 17em;
    height: 2.2em;
    background: #002fff;
    border-color: #002fff;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }
}

.tables-in-profile-container {
  width: 50%;
  border-radius: 19px;
  background: rgba(196, 194, 194, 0.2);
  border-radius: 54px;
  text-align: center;
  padding: 0;
}

.cancelation-reasons-dropdown .css-1s2u09g-control {
  width: 100%;
  height: 100% !important;
  align-items: center;
  background: #ffffff !important;
  border-color: rgb(0, 0, 0) !important;
  border-radius: 50px !important;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em !important;
  font-family: 'Almarai', sans-serif;
  justify-content: space-between;
  min-height: 1vw;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  outline: 0px !important;
}

.cancelation-reasons-dropdown .css-8mmkcg {
  display: none !important;
}

.cancelation-reasons-dropdown-active .css-8mmkcg {
  display: none !important;
}

.cancelation-reasons-dropdown:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 20px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: rgb(0, 0, 0) transparent transparent transparent;
}

.cancelation-reasons-dropdown-active::after {
  position: absolute;
  content: "";
  bottom: 16px;
  right: 20px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: transparent transparent rgb(0, 0, 0);
}

.AF-reinstate-comment-text,
.contract-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 550;
  text-transform: capitalize;
  color: #000000;
  font-size: 0.9rem;
}

.AF-reinstate-comment-icon {
  margin-left: 0.3rem;
  font-size: 1.5rem;
  color: #000000;
}

.cursor-pointer {
  cursor: pointer;
}
.home-label-form {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    letter-spacing: 0.02em;
    color: #000000;
}

.date-home-input {
    background: white;
    width: 90%;
    height: 2.5vw;
    font-weight: 800;
    font-size: 0.8vw;
    font-weight: 400;
    border-radius: 50px;
    border-color: #000000;
}

.home-filter-date-btn {
    width: 33%;
    height: 2.5vw;
    font-weight: 800;
    font-size: 0.8vw;
    font-weight: 400;
    color: white;
    font-family: 'Almarai', sans-serif;
    background: #000000;
    border-radius: 50px;
    font-style: normal;
    border-style: none;
    outline-style: none;
}

.homeCard-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    /* font-weight: 800; */
    font-size: 18px;
    letter-spacing: 0.02em;
    padding: 7px;
    /* text-decoration: underline; */
    color: #000000;
}

.home-card-number {
    font-family: 'Lobster', cursive;
    font-family: 'Merriweather', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #00BD85;
}

.charts-container-layout {
    position: absolute;
    left: 13%;
    width: 80%;
    top: 51rem;
}

.charts-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.home-pie-chart {
    width: 27vw;
    height: 27vh;
    margin-top: 8%;
    margin-left: -4%;
    font-family: 'Lobster', cursive;
    font-family: 'Merriweather', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.5vw;
}

.chart-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1.1vw;
    width: 87%;
    text-align: center;
}

.charts-info-container {
    display: flex;
    justify-content: center;
    padding-top: 2em;
}

.charts-info {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.1vw;
    width: 87%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.home-select-city-filter {
    width: 90%;
    height: 2.5vw;
    font-weight: 800;
    font-size: 0.8vw;
    font-weight: 400;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
}

.error-chart-response-icon {
    font-size: 1.5vw;
    color: red;
}

.error-chart-response-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    letter-spacing: 0.02em;
    margin-left: 0.5vw;
    color: red;
}

/* 120% l fo2  */
@media only screen and (max-width: 1597px) {
    .homeCard-title {
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        /* font-weight: bold; */
        font-size: 15px;
        letter-spacing: 0.02em;
        padding: 6px;
        /* text-decoration: underline; */
        color: #000000;
    }

    .home-card-number {
        font-family: 'Lobster', cursive;
        font-family: 'Merriweather', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        color: #00BD85;
    }

}

/* 150 l fo2 */
@media only screen and (max-width: 1300px) {
    .homeCard-title {
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        /* font-weight: bold; */
        font-size: 13px;
        letter-spacing: 0.02em;
        padding: 5px;
        /* text-decoration: underline; */
        color: #000000;
    }

    .home-card-number {
        font-family: 'Lobster', cursive;
        font-family: 'Merriweather', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 23px;
        color: #00BD85;
    }

}
.message-btn{
    padding: 1%;
    color: #000000;
    margin-right:0.5em;
    margin-top:0.1em; /*de el zadet b3d el tooltip*/
    cursor: pointer;
    font-size: 2vw;
}
.reinstate-btn{
    width: 30%;
    padding: 1%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #00BD85;
    border-radius: 25px;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
    margin-right:1em
}
.cancel-retention-btn{
    width: 30%;
    padding: 1%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
}
.cancel-retention-modal-btn{
    width: 30%;
    padding: 1%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
}
.cancel-msg-modal-header{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3vw;
    letter-spacing: 0.02em;
    color: #000000;
}
.cancel-msg-modal-title{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    letter-spacing: 0.02em;
    color: #000000;
    margin-bottom: -0.9rem;
}
.cancelled-by{
    color: red;
    padding-left: 0.5vw;
    font-family: 'Merriweather', serif;    
    font-weight: bold;
    text-transform: capitalize;
    text-decoration: underline;
    font-size: 1rem;
}
.reason-box {
    padding: 0.7vw;
    border: 1px solid #000000;
    border-radius: 15px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
}
.dasboardModal .modal-content{
    width: 30vw !important ;
    border-radius: 39px;
    padding-top: 1.2vh;
    padding-bottom: 1.2vh;
    margin-left: 30%;
    /* margin-bottom: 30%; */
    margin-top:20vh
  }
  /* .dasboardModal .modal-header .btn-close{
    border: 2px solid black !important;
    border-radius: 50% !important;
    padding: 0.5vw;
    font-size: 0.6vw !important;
    opacity: 0.8 !important;
    border-style: none;
    outline-style: none;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    } */

  .action-confirm-text{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3vw;
    letter-spacing: 0.02em;
    color: #000000;
  }
.comments-loader{
    width: 25px;
    height: 25px;
    margin-left: 1em;
}
.comments-spinner-container{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2vw;
    letter-spacing: 0.02em;
    color: #fffb00;
  }  


@media only screen and (max-width: 1600px) {
    .reinstate-btn{
        width: 30%;
        padding: 0.8%;
        font-family: 'Almarai', sans-serif;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 25px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;    
        margin-right:1em
    }
    .cancel-retention-btn{
        width: 30%;
        padding: 0.8%;
        font-family: 'Almarai', sans-serif;
        color: #FFFFFF;
        background: #000000;
        border-radius: 25px;
        border-color: #000000;
        border-style: none;
        outline-style: none;    
    }
    .cancel-retention-modal-btn{
        width: 30%;
        padding: 1.7%;
        font-family: 'Almarai', sans-serif;
        color: #FFFFFF;
        font-size: 12px;
        background: #000000;
        border-radius: 25px;
        border-color: #000000;
        border-style: none;
        outline-style: none;
    }    
    
}
.retention-comment-title {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}
.retention-comment-subtitle {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    color: #666464;
    margin-left: 0.5rem;

}
.message-btn {
    padding: 1%;
    color: #000000;
    margin-right: 0.5em;
    margin-top: 0.1em;
    /*de el zadet b3d el tooltip*/
    cursor: pointer;
    font-size: 2vw;
}

.reinstate-btn {
    width: 30%;
    padding: 1%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #00BD85;
    border-radius: 25px;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
    margin-right: 1em
}

.cancel-retention-btn {
    width: 30%;
    padding: 1%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
}

.cancel-retention-modal-btn {
    width: 30%;
    padding: 1%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
}

.cancel-msg-modal-header {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3vw;
    letter-spacing: 0.02em;
    color: #000000;
}

.cancel-msg-modal-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #000000;
    margin-bottom: -0.9rem;
}

.cancelled-by {
    color: red;
    padding-left: 0.5vw;
    font-family: 'Merriweather', serif;
    font-weight: bold;
    text-transform: capitalize;
    text-decoration: underline;
    font-size: 1rem;
}

.reason-box {
    padding: 0.7vw;
    border: 1px solid #000000;
    border-radius: 15px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
}

.dasboardModal .modal-content {
    width: 30vw !important;
    border-radius: 39px;
    padding-top: 1.2vh;
    padding-bottom: 1.2vh;
    margin-left: 30%;
    /* margin-bottom: 30%; */
    margin-top: 20vh
}

.action-confirm-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3vw;
    letter-spacing: 0.02em;
    color: #000000;
}

.comments-loader {
    width: 25px;
    height: 25px;
    margin-left: 1em;
}

.comments-spinner-container {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2vw;
    letter-spacing: 0.02em;
    color: #fffb00;
}

@media only screen and (max-width: 1600px) {
    .reinstate-btn {
        width: 30%;
        padding: 0.8%;
        font-family: 'Almarai', sans-serif;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 25px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
        margin-right: 1em
    }

    .cancel-retention-btn {
        width: 30%;
        padding: 0.8%;
        font-family: 'Almarai', sans-serif;
        color: #FFFFFF;
        background: #000000;
        border-radius: 25px;
        border-color: #000000;
        border-style: none;
        outline-style: none;
    }

    .cancel-retention-modal-btn {
        width: 30%;
        padding: 1.7%;
        font-family: 'Almarai', sans-serif;
        color: #FFFFFF;
        font-size: 12px;
        background: #000000;
        border-radius: 25px;
        border-color: #000000;
        border-style: none;
        outline-style: none;
    }

}

.retention-comment-title {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}
.retention-comment-subtitle {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    color: #666464;
    margin-left: 0.5rem;

}
.add-new-shipment{
    width: 14em;
    height: 3em;
    background: #00BD85;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    text-transform: capitalize;
    text-align: center;
    color: #FFFFFF;
    border-style: none;
    outline-style: none;
  }
  .damage-btn, .adjust-btn{
    width: 11em;
    height: 3em;
    background: #000000;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    text-transform: capitalize;
    text-align: center;
    color: #FFFFFF;
    border-style: none;
    outline-style: none;
    margin-right: 1em;
  }
  .posCard{
    width: 13.5rem!important;
    line-height: 0.8em!important;
    box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.2)!important; /* this adds the "card" effect */
    border-radius: 30px!important;
    height: 11.5rem!important;
  }
.posCard-title{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 1.3em;
}
.posCard-images{
  width: 1.8em;
  height: 2em;
}
.posCard-end{
  display: flex;
  margin-top: 2.5em;
}
.poscard-number{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  color: #00BD85;
}
.poscard-end-text{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  margin-top: 0.4em;
  margin-left: 0.2em;
}
.posLogs{
  margin-top: 7em;
  margin-bottom: 2em;
  margin-left: 2em;
}
.posLogs-title{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #000000;
}
.logs-arrows-img{
  width: 2.5em;
  height: 1.1em;
  margin-left: 1em;
  margin-top: 0.7em;
}
.logs-data{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
  margin-right: 1em;
}
.logs-info{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
  margin-top: -1em;
}
.logs-container{
  padding-left: 1.7em;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: 1em;
  border-left: 2px solid #00BD85;
}
.damage-input, .newShipment-input{
  background: #E5E5E5;
  border-radius: 50px;
  font-size: 13px;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 19px;
  padding-right: 19px;
}
.posActionModal .modal-content{
  width: 38em !important ;
  border-radius: 39px;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  margin-bottom: 25%;
  margin-left: 25%;

}

  /* 120% l fo2  */
  @media only screen and (max-width: 1597px) {
    .add-new-shipment{
        width: 12em;
        height: 3em;
        background: #00BD85;
        border-radius: 50px;
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 11px;
        text-transform: capitalize;
        text-align: center;
        color: #FFFFFF;
        border-style: none;
        outline-style: none;
      }
      .damage-btn, .adjust-btn{
        width: 10em;
        height: 3em;
        background: #000000;
        border-radius: 50px;
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 11px;
        text-transform: capitalize;
        text-align: center;
        color: #FFFFFF;
        border-style: none;
        outline-style: none;
        margin-right: 1em;
      }
      .posCard{
        width: 11.7rem!important;
        height: 9.7rem!important;
        line-height: 0.8em!important;
        border-radius: 30px!important;
    }
    .posCard-title{
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      letter-spacing: 0.02em;
      color: #000000;
      margin-top: 1.3em;
    }
    .posCard-images{
      width: 1.5em;
      height: 1.7em;
    }      
    .posCard-end{
      display: flex;
      margin-top: 2em;
    }
    .poscard-number{
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      color: #00BD85;
    }
    .poscard-end-text{
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      color: #000000;
      margin-top: 0.4em;
      margin-left: 0.2em;
    }
    .posLogs{
      margin-top: 5em;
      margin-bottom: 2em;
      margin-left: 2em;
    }   
    .posLogs-title{
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: #000000;
    }
    .logs-arrows-img{
      width: 2.4em;
      height: 1em;
      margin-left: 1em;
      margin-top: 0.5em;
    }
    .logs-container{
      padding-left: 1.3em;
      padding-top: 1em;
      padding-bottom: 1em;
      margin-top: 1em;
      border-left: 2px solid #00BD85;
    }
    .damage-input, .newShipment-input{
      background: #E5E5E5;
      border-radius: 50px;
      font-size: 13px;
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      padding-top: 9px;
      padding-bottom: 9px;
      padding-left: 19px;
      padding-right: 19px;
    }
    .posActionModal .modal-content{
      width: 32em !important ;
      border-radius: 39px;
      padding-top: 0.3em;
      padding-bottom: 0.1em;
      margin-bottom: 25%;
      margin-left: 27%;
    }
    
    
    
  }
  /* 150 l fo2 */
  @media only screen and (max-width: 1300px) {
    .posCard{
      width: 10.3rem!important;
      height: 9rem!important;
      line-height: 0.8em!important;
      border-radius: 30px!important;
  }
  .posCard-title{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.02em;
    color: #000000;
    margin-top: 1.3em;
  }
  .posCard-images{
    width: 1.3em;
    height: 1.5em;
  }      
  .posCard-end{
    display: flex;
    margin-top: 2em;
  }
  .poscard-number{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: #00BD85;
  }
  .poscard-end-text{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #000000;
    margin-top: 0.4em;
    margin-left: 0.2em;
  }
  .posLogs{
    margin-top: 4em;
    margin-bottom: 2em;
    margin-left: 2em;
  }   
  .posLogs-title{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
  }
  .logs-arrows-img{
    width: 2.3em;
    height: 0.9em;
    margin-left: 1em;
    margin-top: 0.5em;
  }
  .damage-input, .newShipment-input{
    background: #E5E5E5;
    border-radius: 50px;
    font-size: 13px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 19px;
    padding-right: 19px;
  }
  .posActionModal .modal-content{
    width: 30em !important ;
    border-radius: 39px;
    padding-top: 0.2em;
    padding-bottom: 0.1em;
    margin-bottom: 25%;
  }
  .posActionModal .modal-content{
    width: 30em !important ;
    border-radius: 39px;
    padding-top: 0.2em!important;
    padding-bottom: 0.1em!important;
    margin-bottom: 25%;
    margin-left: 27%;
  }

}


.role-edit-btn-cities{
    width: 43%;
    height: 12%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;  
  }    

.img-error-screen{
    width: 18vw;
    margin-top: 12vh;
}
.error-screen-text-msg{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    letter-spacing: 0.02em;
    color: #F4B844;  
    margin-top: 6vh;
}
.back-home-btn{
    margin-top: 6vh;
    width: 80%;
    padding: 4.5%;
    font-family: 'Almarai', sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: #FFFFFF;
    background: #00BD85;
    border-radius: 50px;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
}
  /* 120% l fo2  */
  @media only screen and (max-width: 1597px) {
    .error-screen-text-msg{
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        letter-spacing: 0.02em;
        color: #F4B844;  
        margin-top: 7vh;
    }
    .back-home-btn{
        margin-top: 6vh;
        width: 80%;
        padding: 4%;
        font-family: 'Almarai', sans-serif;
        font-weight: bold;
        font-size: 17px;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 50px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
    }
    
    
  }
/* 150 l fo2 */
@media only screen and (max-width: 1300px) {
    .error-screen-text-msg{
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        letter-spacing: 0.02em;
        color: #F4B844;  
        margin-top: 7vh;
    }
    .back-home-btn{
        margin-top: 6vh;
        width: 80%;
        padding: 4%;
        font-family: 'Almarai', sans-serif;
        font-weight: bold;
        font-size: 17px;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 50px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
    }
    
}   

/* mobile */
@media only screen and (max-width: 1100px) {
    .img-error-screen{
        width: 18vw;
        margin-top: 40vh;
    }
    .error-screen-text-msg{
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        letter-spacing: 0.02em;
        color: #F4B844;  
        margin-top: 4vh;
    }
    .back-home-btn{
        margin-top: 4vh;
        width: 100%;
        padding: 4%;
        font-family: 'Almarai', sans-serif;
        font-size: 11px;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 50px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
    }

}    
.img-error-screen{
    width: 18vw;
    margin-top: 12vh;
}
.error-screen-text-msg{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    letter-spacing: 0.02em;
    color: #F4B844;  
    margin-top: 6vh;
}
.back-home-btn{
    margin-top: 6vh;
    width: 80%;
    padding: 4.5%;
    font-family: 'Almarai', sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: #FFFFFF;
    background: #00BD85;
    border-radius: 50px;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
}
  /* 120% l fo2  */
  @media only screen and (max-width: 1597px) {
    .error-screen-text-msg{
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        letter-spacing: 0.02em;
        color: #F4B844;  
        margin-top: 7vh;
    }
    .back-home-btn{
        margin-top: 6vh;
        width: 80%;
        padding: 4%;
        font-family: 'Almarai', sans-serif;
        font-weight: bold;
        font-size: 17px;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 50px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
    }
    
    
  }
/* 150 l fo2 */
@media only screen and (max-width: 1300px) {
    .error-screen-text-msg{
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        letter-spacing: 0.02em;
        color: #F4B844;  
        margin-top: 7vh;
    }
    .back-home-btn{
        margin-top: 6vh;
        width: 80%;
        padding: 4%;
        font-family: 'Almarai', sans-serif;
        font-weight: bold;
        font-size: 17px;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 50px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
    }
    
}   

/* mobile */
@media only screen and (max-width: 1100px) {
    .img-error-screen{
        width: 18vw;
        margin-top: 40vh;
    }
    .error-screen-text-msg{
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        letter-spacing: 0.02em;
        color: #F4B844;  
        margin-top: 4vh;
    }
    .back-home-btn{
        margin-top: 4vh;
        width: 100%;
        padding: 4%;
        font-family: 'Almarai', sans-serif;
        font-size: 11px;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 50px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;
    }

}    
.form-label-text{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 1rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }
  .form-extract-report-selectbox{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }
.form-extract-report-selectbox .css-qc6sy-singleValue{
  color: #89888d;
}
.form-extract-report-date{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #7c7c8d;
}
.export-report-btn{
  background: #00BD85;
  border: 1px solid #00BD85;
  border-radius: 10px;
  color: #ffffff;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size:1.1rem;
  opacity: 0.9;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  margin-top: 10rem;
  margin-right: 11.3%;
  outline: none;
  
}
.export-report-btn:hover{
  /* transform: scale(1.1,1.1); */
  box-shadow: 10px 10px 10px #9ce6cf;
}
.text-area-relation-comment{
    border-radius: 5px;
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 0.02em;
}
.compliance-status{
    font-weight: 500;
    font-size: 1.5rem;
    vertical-align: middle;
    letter-spacing: 0.02em;
    color: #000000;
}
/* .css-1pahdxg-control{
    height: 1px;
} */

/* .fixed-navbar {
  background-color: white;
  position: fixed;
} */
.create-lead-btn-positioning{
  margin-left: 22rem;
  position: fixed;
  z-index: 999;
}

.acquisition-date-flex-label {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 0.6rem;
  margin-right: 0.4rem;
}

.acquisition-date {
  background: white;
  width: 68%;
  height: 100%;
  font-size: 0.9em;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  border-radius: 50px;
  border-color: #000000;
}

.create-lead-btn {
  /* position: absolute; */
  width: 7em;
  height: 3em;
  /* margin-left:33vw;
  margin-top: 10%; */
  color: white;
  font-family: 'Almarai', sans-serif;
  background: #c01313;
  border-radius: 50px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  border-style: none;
  outline-style: none;
}

.acquisition-create-lead-labels {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
  width: 50%;
}

.acquisition-create-lead-inputs {
  /* background: #E5E5E5; */
  width: 60%;
  height: 2.3rem;
  font-size: 0.9em;
  border-radius: 5px;
  margin-left: 1rem;
  border-style: none;
  outline: none;
}

.acquisition-create-lead-inputs.form-control:focus {
  color: #212529;
  border-color: none;
  outline: 0;
  box-shadow: none;
  box-shadow: 0 0 0 0.25rem rgb(228, 231, 231);
}

.acquisition-modals-containers {
  padding-left: 1.2rem;
  padding-right: 1rem;
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;
  border-radius: 20px;
  min-height: 15rem;
  text-align: justify;
}

.acquisition-create-lead-select,
.acquisition-create-lead-select:hover {
  width: 60% !important;
  height: 2rem !important;
  font-size: 0.9em;
  border-radius: 5px;
  margin-left: 1rem;
  border-style: none !important;
  border-color: rgb(255, 255, 255) !important;
  outline: 0px !important;
  align-items: center;
  background: #ffffff !important;
  font-family: 'Almarai', sans-serif;
  margin-top: -0.3rem;
}

.acquisition-create-lead-select .css-1s2u09g-control {
  border-color: rgb(255, 255, 255) !important;
  border-style: none !important;
  outline: 0px !important;

}

.acquisition-modal-action-btn {
  border-radius: 7px;
  color: #ffffff;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9em;
  opacity: 1;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  outline: none;
}

.acquisition-modal-action-btn:hover {
  border-radius: 7px;
  color: #ffffff;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9em;
  opacity: 0.8;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  outline: none;

}

.acquisition-discard-btn {
  background: rgb(212, 212, 13);
  border: 1px solid rgb(212, 212, 13);
  margin-left: 0.5rem;
}

.acquisition-edit-btn {
  background: #0087bd;
  border: 1px solid #0087bd;
}

.acquisition-delete-btn {
  background: rgb(255, 0, 0);
  border: 1px solid rgb(255, 0, 0);
}

.acquisition-close-btn {
  background: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
}

.acquisitionModal {
  margin-top: 5rem;
}

.history-container {
  display: flex;
  flex-flow: wrap;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 0.9em;
  letter-spacing: 0.02em;
  color: rgb(0, 0, 0);
  background-color: rgb(247, 185, 52);
  margin-top: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  width: 80%;
  border-radius: 3px;
}

.acquisitionComment:placeholder-shown,
.acquisitionComment:not(:placeholder-shown) {
  border-radius: 5px;
  border: 3px solid black;
  background-color: rgb(255, 246, 166);
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-size: 0.9rem;
  letter-spacing: 0.02em;
  box-shadow: none;
}

.acquisition-confirm-btn {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  background: #ccd4da;
  border: 2px solid black;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.acquisition-card {

  max-width: 90%;
  min-height: 6rem;
  line-height: 1em;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  text-align: center;
  margin-top: 1rem;
  color: rgb(0, 0, 0);
  /* background-color: rgb(240, 232, 220); */
}

.cardText {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 550;
  line-height: 1.3rem;
  /* margin-top: 1em; */
  font-size: 0.7rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.acquisition-table-titles-border {
  border: 2px solid rgb(0, 0, 0);
  padding: 0.5rem 0.5rem;
  border-radius: 7px;
}

/* .crm-table td,
.crm-table tbody,
.crm-table td,
.crm-table tfoot,
.crm-table th,
.crm-table thead
 {
  border-style: none !important;
}
.crm-table thead tr th{
  border: 3px solid black !important;
  padding: 0.2rem 0.5rem -1rem 0.5rem!important;
  border-radius: 7px;
}
.crm-table thead{
 background-color: #c01313!important;
 position: sticky;
 top: 0;
 z-index: 99;
 background-color: white;
 border: 3px solid black !important;
 padding: 0.2rem 0.5rem -1rem 0.5rem!important;
 border-radius: 7px;

} */
.Ap-table-layout.acquisition-table th {
  border: 2px solid rgb(0, 0, 0) !important;
  padding: 0.5rem 0.5rem !important;
  border-radius: 70px !important;
  margin-bottom: 29rem !important;
}
.screen-otp-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 2.5rem;
    letter-spacing: 0.02em;
    color: #000000;
}

.screen-otp-phoneNumber {
    background: #E5E5E5;
    border-radius: 7px;
    font-size: 1rem;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.02em;
}

.screen-otp-submit-btn {
    background: #00BD85;
    border: 1px solid #00BD85;
    color: #fff;
    border-radius: 7px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 0.02em;
    padding-left: 0.6vw;
    padding-right: 0.6vw;
    padding-top: 0.3vw;
    padding-bottom: 0.3vw;
    outline: none;
    min-width: 10rem;
}
.screen-otp-submit-btn:hover{
    box-shadow: 7px 7px 7px #d2eee6;
}


.screen-otp--value-result {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Merriweather', serif;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.02em;
    margin-top: 3rem;
}

.screen-otp-error-msg{
    color: red;
    font-size: 1rem;
}
.screen-otp-value{
    color: #000000;
    font-size: 1.5rem;
}
.screen-otp-loading{
    color: #000000;
    font-size: 1rem;
}
