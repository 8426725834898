.agent-msg{
    font-size: 14px!important;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #000000;
}
@media only screen and (max-width: 1275px) {
    .agent-msg{
    font-size: 12px!important;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #000000;
  
}
}
@media only screen and (max-width: 1766px) {
    .agent-msg{
      font-size: 12px!important;
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.02em;
      color: #000000;
    
    }
}  