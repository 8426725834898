.message-btn{
    padding: 1%;
    color: #000000;
    margin-right:0.5em;
    margin-top:0.1em; /*de el zadet b3d el tooltip*/
    cursor: pointer;
    font-size: 2vw;
}
.reinstate-btn{
    width: 30%;
    padding: 1%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #00BD85;
    border-radius: 25px;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
    margin-right:1em
}
.cancel-retention-btn{
    width: 30%;
    padding: 1%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
}
.cancel-retention-modal-btn{
    width: 30%;
    padding: 1%;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
}
.cancel-msg-modal-header{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3vw;
    letter-spacing: 0.02em;
    color: #000000;
}
.cancel-msg-modal-title{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    letter-spacing: 0.02em;
    color: #000000;
    margin-bottom: -0.9rem;
}
.cancelled-by{
    color: red;
    padding-left: 0.5vw;
    font-family: 'Merriweather', serif;    
    font-weight: bold;
    text-transform: capitalize;
    text-decoration: underline;
    font-size: 1rem;
}
.reason-box {
    padding: 0.7vw;
    border: 1px solid #000000;
    border-radius: 15px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
}
.dasboardModal .modal-content{
    width: 30vw !important ;
    border-radius: 39px;
    padding-top: 1.2vh;
    padding-bottom: 1.2vh;
    margin-left: 30%;
    /* margin-bottom: 30%; */
    margin-top:20vh
  }
  /* .dasboardModal .modal-header .btn-close{
    border: 2px solid black !important;
    border-radius: 50% !important;
    padding: 0.5vw;
    font-size: 0.6vw !important;
    opacity: 0.8 !important;
    border-style: none;
    outline-style: none;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    } */

  .action-confirm-text{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3vw;
    letter-spacing: 0.02em;
    color: #000000;
  }
.comments-loader{
    width: 25px;
    height: 25px;
    margin-left: 1em;
}
.comments-spinner-container{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2vw;
    letter-spacing: 0.02em;
    color: #fffb00;
  }  


@media only screen and (max-width: 1600px) {
    .reinstate-btn{
        width: 30%;
        padding: 0.8%;
        font-family: 'Almarai', sans-serif;
        color: #FFFFFF;
        background: #00BD85;
        border-radius: 25px;
        border-color: #00BD85;
        border-style: none;
        outline-style: none;    
        margin-right:1em
    }
    .cancel-retention-btn{
        width: 30%;
        padding: 0.8%;
        font-family: 'Almarai', sans-serif;
        color: #FFFFFF;
        background: #000000;
        border-radius: 25px;
        border-color: #000000;
        border-style: none;
        outline-style: none;    
    }
    .cancel-retention-modal-btn{
        width: 30%;
        padding: 1.7%;
        font-family: 'Almarai', sans-serif;
        color: #FFFFFF;
        font-size: 12px;
        background: #000000;
        border-radius: 25px;
        border-color: #000000;
        border-style: none;
        outline-style: none;
    }    
    
}
.retention-comment-title {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}
.retention-comment-subtitle {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    color: #666464;
    margin-left: 0.5rem;

}