.role-permissions-select .css-1s2u09g-control {
    background-color: #E5E5E5;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Almarai';
}

.role-multiselect-actions {
    background-color: #E5E5E5;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Almarai';
    color: black;
}

.role-permission-list {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    border: 1px solid black;
    border-radius: 5px;
    padding-top: 0.1rem;
    padding-bottom: 0.2rem;
    font-size: 0.8em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.permission-sub-list {
    color: #7c7e83 !important;
    font-weight: 600 !important;
}

.role-add-btn-with-loader-spinner {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 5.7rem;
    height: 1.9rem;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
}

.roleCloseBtn {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 5.7rem;
    height: 1.9rem;
    background: #ff0000;
    border-color: #ff0000;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-right: 0;
}

