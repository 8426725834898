.form-label-text{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 1rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }
  .form-extract-report-selectbox{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }
.form-extract-report-selectbox .css-qc6sy-singleValue{
  color: #89888d;
}
.form-extract-report-date{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #7c7c8d;
}
.export-report-btn{
  background: #00BD85;
  border: 1px solid #00BD85;
  border-radius: 10px;
  color: #ffffff;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size:1.1rem;
  opacity: 0.9;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  margin-top: 10rem;
  margin-right: 11.3%;
  outline: none;
  
}
.export-report-btn:hover{
  /* transform: scale(1.1,1.1); */
  box-shadow: 10px 10px 10px #9ce6cf;
}