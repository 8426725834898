.clickable-row:hover td {
    cursor: pointer;
    background-color: rgba(182, 182, 182, 0.2);
    font-weight: bold;
    color: #00bd85;
}

.layout-invoices {
    position: absolute;
    width: 83%;
    margin-left: 15%;
    margin-top: 6%;
}

.switch-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    margin-right: 0.5em;
}

.text-label {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.invoices-input {
    background: #E5E5E5;
    width: 70%;
    height: 2.3vw;
    font-size: 0.9vw;
    border-radius: 50px;
}

.profile-input {
    background: #E5E5E5;
    width: 80%;
    height: 2.3vw;
    font-size: 0.9vw;
    border-radius: 50px;
}

.invoices-icon {
    margin-left: 0.3em;
    margin-top: -5px;
    cursor: pointer;
    font-size: 1.3vw;
}

.invoice-img {
    width: 13vw;
    height: 13vw;
}

.add-note-btn {
    width: 7.3vw;
    padding: 0.3vw;
    /* height: 22%; */
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #000000;
    border-radius: 25px;
    border-color: #000000;
    border-style: none;
    outline-style: none;
}

.add-note-icon {
    margin-right: 0.3em;
    font-size: 0.9vw
}

.add-note-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.1vw !important;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.add-note-form-label {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.load_more_text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #ABABAB;
    margin-right: 0.4em;

}

.add-note-form-textarea {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    border-radius: 7px;
    background-color: rgb(246, 244, 247);
}

.submit-addNote-btn {
    width: 48%;
    padding: 0.5vw;
    height: 2.4vw;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #00BD85;
    border-radius: 8px;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
}

.cancel-addNote-btn {
    width: 48%;
    padding: 0.5vw;
    height: 2.4vw;
    /* margin-left: 0.5em; */
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #ff0000;
    background: #ffffff;
    border-radius: 8px;
    border-color: #ff0000;
    border: 1px solid #ff0000;
    outline-style: none;
}

.cancel-addNote-btn:hover {
    width: 48%;
    padding: 0.5vw;
    height: 2.4vw;
    /* margin-left: 0.5em; */
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #ffffff;
    background: #ff0000;
    border-radius: 8px;
    border-color: #ff0000;
    border-style: none;
    outline-style: none;
}

.addNote-footer.modal-footer {
    padding-left: 0;
    padding-right: 0;
}

.note-container {
    background-color: #E5E5E5;
    border-radius: 50px;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    margin-top: 1.3vw;
}

.note-title-wrapper {
    margin-left: 1vw;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #5E5E5E;
    background-color: #ffffff;
    border-radius: 50px;
    padding-left: 2.3vw;
    padding-right: 2.3vw;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
}

.note-main-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #5E5E5E;
}

.profile-img-setting-icon {
    font-style: normal;
    font-weight: 700;
    font-size: 5vw;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    border: 1px solid black;
    border-radius: 50%;
    padding: 1.4vw;
    cursor: pointer;
}

.notification-header {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    letter-spacing: 0.02em;
    color: #000000;
}

.notification-msg-body {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.87rem;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 0.5rem 0.8rem;
    height: 5.7rem;
}

.notification-msg-title::placeholder,
.notification-msg-body::placeholder {
    color: #A6A6A6;
}

.notification-msg-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.87rem;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 0.9rem 0.8rem;
    height: 3.1rem;
}

.switch-notification-options-text {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    color: #000000;
}

.switch-notification-options-text:hover,
.switch-notification-options-text-active:hover {
    cursor: pointer;
}

.switch-notification-options-text-active {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    color: #00BD85;
    padding-bottom: 0.3rem;
    border-bottom: 2px solid #00BD85;
}

.notification-status-title,
.notification-status-checkbox,
.notification-industry-title,
.notification-city-title,
.notification-search-by-number-title {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.87rem;
    color: #000000;
}

.notification-status-checkbox .form-check {
    display: flex;
    align-items: center;
}

.notification-status-checkbox .form-check input {
    width: 1.25;
    height: 1.25;
    border-color: #000;
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
}

.notification-status-checkbox .form-check input:checked {
    background-color: #00BD85;
    border-color: #00BD85;
}

.notification-city-filter .css-1s2u09g-control,
.notification-industry-filter .css-1s2u09g-control,
.notification-city-filter .css-1pahdxg-control,
.notification-industry-filter .css-1pahdxg-control {
    border: 1px solid #707070;
    border-radius: 10px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.87rem;
    height: 3.1rem;
}
.notification-search-by-number-input {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.87rem;
    height: 3.1rem;
    border: 1px solid #707070;
    border-radius: 10px;
}

.notification-search-by-number-input::placeholder {
    color: #A6A6A6;
}

.notification-disabled-search-input {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.87rem;
    height: 3.1rem;
    border-radius: 10px;
    background-color: #eeeeee;
}

.notification-search-by-number-loader {
    background-color: #eeeeee;
    color: #A6A6A6;
}

.notification-search-by-number-loader .spinner-border {
    width: 1rem;
    height: 1rem;
    font-size: 0.5rem;
}

.notification-result-names {
    border: 1px solid #707070;
    border-radius: 10px;
    height: 7.6rem;
    overflow-y: scroll;
    padding: 1rem;
}

.notification-searched-name {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    border: 1px solid #707070;
    border-radius: 10px;
    color: #A6A6A6;
    min-height: 2.1rem;
    max-width: auto;
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
    overflow: hidden;
    word-wrap: break-word;
}
.notification-search-by-number-error-msg{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.87rem;
    color: red;
}
.notification-delete-name-icon {
    font-size: 0.9rem;
}

.notification-delete-name-icon:hover {
    color: #000;
    cursor: pointer;
}

.notification-footer-btn {
    width: 100%;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    background: #00BD85 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 1rem;
    border-style: none;
    outline-style: none;
    height: 2.5rem;
}

.multi-select-label-accounts.multiSelectContainer {
    background: #E5E5E5;
    width: 80%;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.multi-select-filters-accounts.multiSelectContainer {
    background: #ffffff;
    width: 100%;
    z-index: 999;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.8rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    /* border: 1px solid #5E5E5E; */
    border-style: none;
    outline-style: none;
}

.multi-select-filters-accounts.multiSelectContainer ul {
    /* max-height: 11vh; */
}

.accounts-date-filter {
    width: 100%;
    min-height: 2.3rem;
    margin-top: 0.4rem;
    z-index: 999;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.8rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #7c7c7c;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.industrySelectbox .css-1s2u09g-control,
.industrySelectbox .css-b62m3t-container,
.industrySelectbox .css-1pahdxg-control,
.industrySelectbox .css-lgtuOrj-indicatorContainer,
.citySelectbox .css-1s2u09g-control,
.citySelectbox .css-b62m3t-container,
.citySelectbox .css-1pahdxg-control,
.citySelectbox .css-lgtuOrj-indicatorContainer {
    background: #E5E5E5;
    border: 1px solid #ccc;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    min-height: 2.2rem;
}

.profile-label-text {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.sms-input-field {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9em;
    /* line-height: 0.02em; */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    background-color: #ffffff !important;
    border-radius: 50px;
    border: 1px solid #000000;
    padding: 0.5rem;
}

.account-profile-titles {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 600;
    /* font-size: 1.5rem; */
    line-height: 0.02em;
    letter-spacing: 0.02em;
    /* text-transform: capitalize; */
    color: #00BD85
}

.profile-store-payments-btn-selected {
    background: #00BD85;
    height: 3rem;
    width: 100%;
    border-radius: 40px;
    border: none;
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    text-transform: capitalize;
    color: #FFFFFF;
}

.profile-store-payments-btn-unselected {
    background: 0;
    height: 3rem;
    width: 100%;
    border-radius: 40px;
    border: none;
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    text-transform: capitalize;
    color: #5E5E5E;
}

.create-invoice-btn {
    background: #64024c;
    height: 3rem;
    width: 40%;
    border-radius: 40px;
    border: 0;
    outline: none;
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    text-transform: capitalize;
    color: #FFFFFF;

}

.ws-name-in-invoice-modal {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #07506d;
    margin-left: 0.5rem;
    /* border-bottom: 2px solid #0a96cd;
    padding-bottom: 0.3rem;
    display: inline-block; */
}

.ws-dates-in-invoice-modal {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #07506d;
    text-align: center;
}

.add-new-item-in-invoice {
    vertical-align: middle;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.95em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #00BD85;
    cursor: pointer;
}

.invoice-recycle-bin {
    width: 40%;
    height: 100%;
    color: #eeb5b5;
    cursor: pointer;
    /* margin-top: 0.8rem; */
}

.invoice-recycle-bin:hover {
    width: 40%;
    height: 100%;
    color: #ff0000;
    cursor: pointer;
    /* margin-top: 0.8rem; */
}

.borderBottom {
    border-bottom: 2px solid #000;
    display: inline-block;
    min-width: 9rem;
}

.transaction-back-btn {
    width: 48%;
    padding: 0.5vw;
    height: 2.4vw;
    /* margin-left: 0.5em; */
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #ffffff;
    background: #000000;
    border-radius: 8px;
    /* border-color: #000000; */
    border: 1px solid #000000;
    outline-style: none;
}

.transaction-border-bottom-dates {
    border: 2px solid #646046;
    padding: 2px 5px 2px 5px;
}

.ws-social-links {
    width: 100%;
}

.ws-sales-number {
    width: 20rem;
}

.ws-social-links-container {
    /* background: #e9ecef;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 10px 10px 5px #c1c3c5; */

    background: #e9ecef;
    width: 15rem;
    height: 11rem;
    border-radius: 13%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 10px 10px 5px #c1c3c5;

}

.ws-sales-number-container:hover,
.ws-social-links-container:hover {
    opacity: 0.8;
    cursor: pointer;
}

.salesNumbersInputs {
    background: #E5E5E5;
    width: 90%;
    height: 2.3vw;
    font-size: 12px;
    font-weight: 400;
    border-radius: 10px;
}

.ws-profile-telegram-icon {
    color: #0088cc;
    font-size: 1.5rem;
}

.ws-profile-whatsapp-icon {
    color: #0ac40acf;
    font-size: 1.5rem;
}

.ws-profile-instagram-icon {
    position: relative;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 20%;
    background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)
}

.ws-profile-instagram-icon:after,
.ws-profile-instagram-icon:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.9rem;
    height: 0.9rem;
    border: 2px solid #fff;
    transform: translate(-50%, -50%);
    content: ''
}

.ws-profile-instagram-icon:before {
    border-radius: 20%
}

.ws-profile-instagram-icon:after {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%
}

.ws-profile-facebook-icon {
    color: #3b5998;
    font-size: 1.5rem;
}

.ws-profile-salesnumber-call-icon {
    color: #040c1d;
    font-size: 1.5rem;
}

.create-pos-modal-title,
.change-pos-password-modal-title {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 800;
    font-size: 1.1em;
    line-height: 0.02em;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.recyle-bin-icon-in-profile-confirmation-modal {
    color: red;
    font-size: 3rem;
    margin-bottom: 1rem;
}

.catchErrorMsg {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.9rem;
    letter-spacing: 0.02em;
    color: rgb(255, 0, 0);
}

.sync-btn-progress-behavior,
.sync-btn-pointer-behavior,
.download-btn-pointer-behavior,
.download-btn-progress-behavior {
    font-size: 2.4rem;
    margin-left: 0.5rem;
}

.sync-btn-pointer-behavior:hover,
.download-btn-pointer-behavior:hover {
    cursor: pointer;
}

.sync-btn-progress-behavior:hover,
.download-btn-progress-behavior:hover {
    cursor: progress;
}