.link-to-profile-icon-through-af {
  margin-left: 0.3em;
  margin-top: -17px;
  cursor: pointer;
  font-size: 1.2vw;
}

.Ap-table tbody,
.Ap-table td,
.Ap-table tfoot,
.Ap-table th,
.Ap-table thead,
.Ap-table tr {
  border-color: 0 !important;
  border-style: none !important;
  border-width: 0 !important;
}

.tableCard {
  width: 10rem !important;
  line-height: 0.8em !important;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.2) !important;
  /* this adds the "card" effect */
  border-radius: 30px !important;
  /* height: 11.5rem!important; */
}

.cardTitle {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;

}

.cardText {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 1.7em;
  margin-top: 1em;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
}

.pending-btn {
  /* width: 33%; */
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #00BD85;
  border-color: #00BD85;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.firstVisit-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #dfee58;
  border-color: #dfee58;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.firstTeam-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #84e4f5;
  border-color: #84e4f5;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.secondTeam-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #11b2ce;
  border-color: #11b2ce;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.contract-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #00BD85;
  border-color: #00BD85;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.posVisit-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #dfee58;
  border-color: #dfee58;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.final-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #08d112;
  border-color: #08d112;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.secondVisit-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #dfee58;
  border-color: #dfee58;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.secondVisitFirstTeam-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #ff7741;
  border-color: #ff7741;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.secondVisitSecondTeam-btn {
  height: 22%;
  font-family: 'Almarai', sans-serif;
  color: #FFFFFF;
  background: #ec4c1b;
  border-color: #ff7741;
  border-style: none;
  outline-style: none;
  font-size: 13px;
  padding: 10px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  border-radius: 10px;
}

.modal-header-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.modal-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.7em;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.AP-modal-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.7em;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
}
.cancel-visit {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #ff0000;
  border-color: #ff0000;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.cancel-visit-team2 {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 9em;
  height: 2.7em;
  background: #ff0000;
  border-color: #ff0000;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.action-visit {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.save-edits {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #0071bd;
  border-color: #0071bd;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.review-visit-team2 {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 10em;
  height: 2.7em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.discount-btn {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 10em;
  height: 2.7em;
  background: #7952B3;
  border-color: #7952B3;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.book-pos-btn {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #000000;
  border-color: #000000;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  margin-left: 5px;
}

.revert-visit {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #F4B844;
  border-color: #F4B844;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.revert-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.revertImg {
  width: 12em;
}

.revertModal .modal-content {
  margin-bottom: 33%;
}

.role-Ap-input.form-control[readonly] {
  background: #ffffff;
  border-color: #000000;
  border-radius: 50px;
}

.Ap-logs {
  border-left: 2px solid #00BD85;
}

.logs-title {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
}

.logs-data {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
  margin-right: 1em;
}

.Ap-logs .info {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
  margin-top: -1em;
}

.Ap-text-area {
  border-radius: 20px;
  padding: 19px;
  background-color: #FFFFFF !important;
}

.Ap-register-image {
  width: 100%;
  height: 100%;
  /* height:4em */
}

.success-modal-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;

}

.server-msg-modal .modal-content {
  min-height: 15em;
}

/* .server-msg-modal .modal-header .btn-close {
  padding: 0.7em;
} */

.Ap-table-layout {
  position: absolute;
  left: 13%;
  width: 100%;
  top: 37%;
  overflow: auto;
}

.Ap-table-layout td,
.Ap-table-layout tbody,
.Ap-table-layout td,
.Ap-table-layout tfoot,
.Ap-table-layout th,
.Ap-table-layout thead,
.Ap-table-layout tr {
  border-style: none !important;
}

.scroll-table {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
  z-index: 99
}

.scroll-table thead th {
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: white;
  bottom: 3em;
  padding-bottom: 2em;
}

.input-img-upload {
  /* background: url("../../icons/uploadImg.png") center / contain no-repeat;
  background-size: 100%;
  width: 100%;
  height: 8em;
  visibility: hidden; */

  /* background-size: 3em */
}

.discount {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 22px; */
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #00BD85;
}

.discount-input {
  background: #E5E5E5;
  border-radius: 50px;
  font-size: 13px;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 19px;
  padding-right: 19px;
}

.amount-input {
  background: #E5E5E5;
  border-radius: 50px;
  font-size: 13px;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 19px;
  padding-right: 19px;
  width: 80px;
}

.discountModal .modal-content {
  width: 50em !important;
  border-radius: 39px;
  padding: 1em;
  margin-bottom: 25%;
}

.submitContract {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 8em;
  height: 2.7em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  text-align: center;
  margin-top: 1.5em;
  padding-top: 0.5em;
}

.thead-profile {
  border-bottom: 0;
}

.profile-active {
  color: rgba(0, 189, 133, 1);
}

.profile-pending {
  color: rgba(244, 184, 68, 1);
}

.profile-inActive {
  color: rgb(255, 0, 0);
}

.download-contract {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  width: 13em;
  height: 2.2em;
  background: #002fff;
  border-color: #002fff;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
  /* margin-left: 100%; */
}

.cancelation-label-form {
  font-size: calc(.1rem + 1vw);
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: #000000;
}

.role-done-btn-loader {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  width: 7em;
  height: 2.3em;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.cancellation-modal-done-btn-loader,
.cancellation-modal-done-btn {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;
  width: 5.5rem;
  height: 2.1rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  background: #00BD85;
  border-color: #00BD85;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

.cancellation-modal-cancel-btn-loader {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;
  width: 5.5rem;
  height: 2.1rem;
  background: #FF0000;
  border-color: #FF0000;
  color: white;
  border-radius: 40px;
  border-style: none;
  outline-style: none;
}

@media only screen and (max-width: 1600px) {
  .tableCard {
    width: 8.5rem !important;
    /* height: 9.5rem!important; */
    line-height: 0.8em !important;
    border-radius: 30px !important;
  }

  .cardTitle {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
  }

  .cardText {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 1.5em;
    margin-top: 1em;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #ABABAB;
  }

  .pending-btn {
    /* width: 33%; */
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #00BD85;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .firstVisit-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #dfee58;
    border-color: #dfee58;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .firstTeam-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #84e4f5;
    border-color: #84e4f5;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .secondTeam-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #11b2ce;
    border-color: #11b2ce;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .contract-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #00BD85;
    border-color: #00BD85;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .posVisit-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #dfee58;
    border-color: #dfee58;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .final-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #08d112;
    border-color: #08d112;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .secondVisit-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #dfee58;
    border-color: #dfee58;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .secondVisitFirstTeam-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #ff7741;
    border-color: #ff7741;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .secondVisitSecondTeam-btn {
    height: 22%;
    padding: 6px;
    font-family: 'Almarai', sans-serif;
    color: #FFFFFF;
    background: #ec4c1b;
    border-color: #ff7741;
    border-style: none;
    outline-style: none;
    font-size: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border-radius: 10px;
  }

  .role-Ap-input {
    height: 2em;
    font-size: 0.9em;
    background: #ffffff;
    border-color: #000000;
    border-radius: 50px;
  }

  .cancel-visit {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #ff0000;
    border-color: #ff0000;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .submitContract {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    text-align: center;
    margin-top: 1.5em;
    padding-top: 0.5em;
  }

  .cancel-visit-team2 {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8.5em;
    height: 2.7em;
    background: #ff0000;
    border-color: #ff0000;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .action-visit {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .save-edits {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #0071bd;
    border-color: #0071bd;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  .review-visit-team2 {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 9em;
    height: 2.7em;
    background: #00BD85;
    border-color: #00BD85;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .discount-btn {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 9em;
    height: 2.7em;
    background: #7952B3;
    border-color: #7952B3;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .book-pos-btn {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #000000;
    border-color: #000000;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }

  .revert-visit {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    width: 8em;
    height: 2.7em;
    background: #F4B844;
    border-color: #F4B844;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
  }

  .revertImg {
    width: 12em;
  }

  .Ap-register-image {
    width: 100%;
    height: 100%;

    /* height:4em */

  }

  .Ap-text-area {
    border-radius: 20px;
    padding: 1px;
    background-color: #FFFFFF !important;
  }

  .Ap-table-layout {
    position: absolute;
    left: 13%;
    width: 90em;
    top: 37%;
    /* overflow: auto; */
  }

  .revertModal .modal-content {
    width: 25em !important;
    border-radius: 39px;
    padding: 1em;
    margin-left: 30%;
    margin-bottom: 35%;
  }

  .discount {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #00BD85;

  }

  .discount-input {
    background: #E5E5E5;
    border-radius: 50px;
    font-size: 10px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .discountModal .modal-content {
    width: 50em !important;
    border-radius: 39px;
    padding: 1em;
    margin-bottom: 25%;
  }

  .amount-input {
    background: #E5E5E5;
    border-radius: 50px;
    font-size: 13px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 19px;
    padding-right: 19px;
    width: 80px;
    height: 30px;
  }

  .download-contract {
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    width: 17em;
    height: 2.2em;
    background: #002fff;
    border-color: #002fff;
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-left: 5px;
  }
}

.tables-in-profile-container {
  width: 50%;
  border-radius: 19px;
  background: rgba(196, 194, 194, 0.2);
  border-radius: 54px;
  text-align: center;
  padding: 0;
}

.cancelation-reasons-dropdown .css-1s2u09g-control {
  width: 100%;
  height: 100% !important;
  -webkit-box-align: center;
  align-items: center;
  background: #ffffff !important;
  border-color: rgb(0, 0, 0) !important;
  border-radius: 50px !important;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em !important;
  font-family: 'Almarai', sans-serif;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 1vw;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  outline: 0px !important;
}

.cancelation-reasons-dropdown .css-8mmkcg {
  display: none !important;
}

.cancelation-reasons-dropdown-active .css-8mmkcg {
  display: none !important;
}

.cancelation-reasons-dropdown:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 20px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: rgb(0, 0, 0) transparent transparent transparent;
}

.cancelation-reasons-dropdown-active::after {
  position: absolute;
  content: "";
  bottom: 16px;
  right: 20px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: transparent transparent rgb(0, 0, 0);
}

.AF-reinstate-comment-text,
.contract-text {
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: 550;
  text-transform: capitalize;
  color: #000000;
  font-size: 0.9rem;
}

.AF-reinstate-comment-icon {
  margin-left: 0.3rem;
  font-size: 1.5rem;
  color: #000000;
}

.cursor-pointer {
  cursor: pointer;
}