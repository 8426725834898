.add-new-shipment{
    width: 14em;
    height: 3em;
    background: #00BD85;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    text-transform: capitalize;
    text-align: center;
    color: #FFFFFF;
    border-style: none;
    outline-style: none;
  }
  .damage-btn, .adjust-btn{
    width: 11em;
    height: 3em;
    background: #000000;
    border-radius: 50px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    text-transform: capitalize;
    text-align: center;
    color: #FFFFFF;
    border-style: none;
    outline-style: none;
    margin-right: 1em;
  }
  .posCard{
    width: 13.5rem!important;
    line-height: 0.8em!important;
    box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.2)!important; /* this adds the "card" effect */
    border-radius: 30px!important;
    height: 11.5rem!important;
  }
.posCard-title{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 1.3em;
}
.posCard-images{
  width: 1.8em;
  height: 2em;
}
.posCard-end{
  display: flex;
  margin-top: 2.5em;
}
.poscard-number{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  color: #00BD85;
}
.poscard-end-text{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  margin-top: 0.4em;
  margin-left: 0.2em;
}
.posLogs{
  margin-top: 7em;
  margin-bottom: 2em;
  margin-left: 2em;
}
.posLogs-title{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #000000;
}
.logs-arrows-img{
  width: 2.5em;
  height: 1.1em;
  margin-left: 1em;
  margin-top: 0.7em;
}
.logs-data{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
  margin-right: 1em;
}
.logs-info{
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ABABAB;
  margin-top: -1em;
}
.logs-container{
  padding-left: 1.7em;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: 1em;
  border-left: 2px solid #00BD85;
}
.damage-input, .newShipment-input{
  background: #E5E5E5;
  border-radius: 50px;
  font-size: 13px;
  font-family: 'Almarai', sans-serif;
  font-style: normal;
  font-weight: bold;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 19px;
  padding-right: 19px;
}
.posActionModal .modal-content{
  width: 38em !important ;
  border-radius: 39px;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  margin-bottom: 25%;
  margin-left: 25%;

}

  /* 120% l fo2  */
  @media only screen and (max-width: 1597px) {
    .add-new-shipment{
        width: 12em;
        height: 3em;
        background: #00BD85;
        border-radius: 50px;
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 11px;
        text-transform: capitalize;
        text-align: center;
        color: #FFFFFF;
        border-style: none;
        outline-style: none;
      }
      .damage-btn, .adjust-btn{
        width: 10em;
        height: 3em;
        background: #000000;
        border-radius: 50px;
        font-family: 'Almarai', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 11px;
        text-transform: capitalize;
        text-align: center;
        color: #FFFFFF;
        border-style: none;
        outline-style: none;
        margin-right: 1em;
      }
      .posCard{
        width: 11.7rem!important;
        height: 9.7rem!important;
        line-height: 0.8em!important;
        border-radius: 30px!important;
    }
    .posCard-title{
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      letter-spacing: 0.02em;
      color: #000000;
      margin-top: 1.3em;
    }
    .posCard-images{
      width: 1.5em;
      height: 1.7em;
    }      
    .posCard-end{
      display: flex;
      margin-top: 2em;
    }
    .poscard-number{
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      color: #00BD85;
    }
    .poscard-end-text{
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      color: #000000;
      margin-top: 0.4em;
      margin-left: 0.2em;
    }
    .posLogs{
      margin-top: 5em;
      margin-bottom: 2em;
      margin-left: 2em;
    }   
    .posLogs-title{
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: #000000;
    }
    .logs-arrows-img{
      width: 2.4em;
      height: 1em;
      margin-left: 1em;
      margin-top: 0.5em;
    }
    .logs-container{
      padding-left: 1.3em;
      padding-top: 1em;
      padding-bottom: 1em;
      margin-top: 1em;
      border-left: 2px solid #00BD85;
    }
    .damage-input, .newShipment-input{
      background: #E5E5E5;
      border-radius: 50px;
      font-size: 13px;
      font-family: 'Almarai', sans-serif;
      font-style: normal;
      font-weight: bold;
      padding-top: 9px;
      padding-bottom: 9px;
      padding-left: 19px;
      padding-right: 19px;
    }
    .posActionModal .modal-content{
      width: 32em !important ;
      border-radius: 39px;
      padding-top: 0.3em;
      padding-bottom: 0.1em;
      margin-bottom: 25%;
      margin-left: 27%;
    }
    
    
    
  }
  /* 150 l fo2 */
  @media only screen and (max-width: 1300px) {
    .posCard{
      width: 10.3rem!important;
      height: 9rem!important;
      line-height: 0.8em!important;
      border-radius: 30px!important;
  }
  .posCard-title{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.02em;
    color: #000000;
    margin-top: 1.3em;
  }
  .posCard-images{
    width: 1.3em;
    height: 1.5em;
  }      
  .posCard-end{
    display: flex;
    margin-top: 2em;
  }
  .poscard-number{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: #00BD85;
  }
  .poscard-end-text{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #000000;
    margin-top: 0.4em;
    margin-left: 0.2em;
  }
  .posLogs{
    margin-top: 4em;
    margin-bottom: 2em;
    margin-left: 2em;
  }   
  .posLogs-title{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
  }
  .logs-arrows-img{
    width: 2.3em;
    height: 0.9em;
    margin-left: 1em;
    margin-top: 0.5em;
  }
  .damage-input, .newShipment-input{
    background: #E5E5E5;
    border-radius: 50px;
    font-size: 13px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 19px;
    padding-right: 19px;
  }
  .posActionModal .modal-content{
    width: 30em !important ;
    border-radius: 39px;
    padding-top: 0.2em;
    padding-bottom: 0.1em;
    margin-bottom: 25%;
  }
  .posActionModal .modal-content{
    width: 30em !important ;
    border-radius: 39px;
    padding-top: 0.2em!important;
    padding-bottom: 0.1em!important;
    margin-bottom: 25%;
    margin-left: 27%;
  }

}

