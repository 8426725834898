.double-action-btns-table{
    margin: 1%;
    padding: 1%;
    width: 20%;
    height: 1.7vw;
    font-family: 'Almarai', sans-serif;
    font-weight:500;
    letter-spacing: 0.02em;
    font-size: 0.9vw;
    color: #FFFFFF;
    /* background: #000000; */
    border-radius: 25px;
    /* border-color: #000000; */
    border-style: none;
    outline-style: none;
}
.labels-box{
    padding-top: 2%;
    padding-bottom: 5%;
    text-align: center;
    border: 1px solid #000000;
    border-radius: 15px;
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.8vw;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}
.labels-box .col-3{
    background-color: #cecbcb;
    border-radius: 20px;
    padding: 0.8%;
    margin-left: 1%;
}
.labels-box span{
    width: 80%;
}
.labels-box .cancel-label{
    width: 20%;
    cursor: pointer;
}
.labels-box .cancel-label-clicked{
    width: 20%;
    cursor:wait;
}
.add-label-btn{
    font-family: 'Almarai', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 5.7rem;
    height: 1.9rem;
    background: rgb(20, 176, 238);
    border-color: rgb(20, 176, 238);
    color: white;
    border-radius: 40px;
    border-style: none;
    outline-style: none;
    margin-right: 0;
  }
  