.dashboard-logs-btn {
    background-color: #5617e9;
    color: rgb(255, 255, 255);
    border: 0px solid #5617e9;
}

.dashboard-logs-Btn:hover {
    opacity: 1;
}
.dashboard-logs-offcanvas{
    margin-left: 12%;
    background-color: #ededed;
    font-family: 'Almarai';
    font-style: normal;
}